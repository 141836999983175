import React from "react";

const PrintBill = ({ data }) => {
    console.log(data)
    const getCurrentFinancialYear = () => {
        var fiscalyear = "";
        var today = new Date();
        if ((today.getMonth() + 1) <= 3) {
            fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
        } else {
            fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
        }
        return fiscalyear
    }
    return (
        <>
            <div style={{ marginLeft: 100 }}>
                <title>
                    D:\uploadedFiles\4b7045fdb5bd17d997aa9415eb185-8a8a90c3f3b821dc\p1hnlm5gmi1e4q63rdd7q2g2g4.pdf
                </title>
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n<!--\nbody { font-family: Arial; font-size: 16.8px }\n.pos { position: absolute; z-index: 0; left: 100px; top: 0px }\n-->\n"
                    }}
                />
                <nobr>
                    <nowrap>
                        <div className="pos" id="_0:0" style={{ top: 75 }}>
                            {data.type === "FID" ?
                                <img
                                    name="_1170:827"
                                    src={require('../Assets/images/FIDNotice.jpg')}
                                    height={1270}
                                    width={887}
                                    border={0}
                                    useMap="#Map"
                                /> :
                                <img
                                    name="_1170:827"
                                    src={require('../Assets/images/noticedraft.jpg')}
                                    height={1270}
                                    width={887}
                                    border={0}
                                    useMap="#Map"
                                />}
                        </div>
                        {/* <div className="pos" id="_371:15" style={{ top: 215, left: 671 }}>
                        <span
                            id="_21.8"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "21.8px",
                                color: "#000000"
                            }}
                        >
                            BOOK
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            POST
                        </span>
                    </div> */}
                        <div className="pos" id="_36:187" style={{ top: 187, left: 136 }}>
                            <span
                                id="_12.2"
                                style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                            >
                                {/* Ref<span style={{ fontFamily: "Times New Roman" }}> </span> No
                            <span style={{ fontFamily: "Times New Roman" }}> </span> :
                            <span style={{ fontFamily: "Times New Roman" }}> </span> MMRI
                            <span style={{ fontFamily: "Times New Roman" }}> </span> &amp;
                            <span style={{ fontFamily: "Times New Roman" }}> </span>{" "} */}

                            </span>
                        </div>
                        <div className="pos" id="_619:195" style={{ top: 282, left: 840 }}>
                            <span
                                id="_12.2"
                                style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                            >
                                {/* Date<span style={{ fontFamily: "Times New Roman" }}> </span>{" "} */}
                                {new Date().toISOString().substring(0, 10)}
                            </span>
                        </div>
                        {/* <div className="pos" id="_355:213" style={{ top: 413, left: 655 }}>
                        <span
                            id="_14.3"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "14.3px",
                                color: "#000000"
                            }}
                        >
                            REMINDER
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            -
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            I
                        </span>
                    </div> */}
                        {/* <div className="pos" id="_36:238" style={{ top: 428, left: 336 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            To,
                        </span>
                    </div> */}
                        <div className="pos" id="_619:238" style={{ top: 348, left: 849 }}>
                            <span
                                id="_11.6"
                                style={{
                                    fontWeight: "bold",
                                    fontFamily: "Arial",
                                    fontSize: "11.6px",
                                    color: "#000000"
                                }}
                            >
                                {data.plot_number}
                                <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                    {"  -  "}
                                </span>
                                <span
                                    style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}
                                ></span>{" "}
                                {data.plotOwnerArea}
                            </span>
                        </div>
                        <div className="pos" id="_36:253" style={{ top: 370, left: 146 }}>
                            <span
                                id="_12.9"
                                style={{
                                    fontWeight: "bold",
                                    fontFamily: "Arial",
                                    fontSize: "12.9px",
                                    color: "#000000"
                                }}
                            >
                                {data.plotOwnerName}
                            </span>
                        </div>
                        <div className="pos" id="_36:253" style={{ top: 283, left: 490 }}>
                            <span
                                id="_12.9"
                                style={{
                                    fontWeight: "bold",
                                    fontFamily: "Arial",
                                    fontSize: "12.9px",
                                    color: "#000000"
                                }}
                            >
                                {data.type === "FID" ? "" : data.type}
                            </span>
                        </div>
                        {/* <div className="pos" id="_36:268" style={{ top: 268, left: 36 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            DINESH<span style={{ fontFamily: "Times New Roman" }}> </span> R
                            <span style={{ fontFamily: "Times New Roman" }}> </span> SINGHAL
                        </span>
                    </div> */}
                        <div className="pos" id="_36:285" style={{ top: 350, left: 146 }}>
                            <span
                                id="_12.9"
                                style={{ fontFamily: "Arial", fontSize: "12.9px", color: "#000000" }}
                            >
                                {data.plotOwnerArea}
                            </span>
                        </div>
                        {/* <div className="pos" id="_36:285" style={{ top: 285, left: 36 }}>
                        <span
                            id="_12.9"
                            style={{ fontFamily: "Arial", fontSize: "12.9px", color: "#000000" }}
                        >
                            {data.plotOwnerMobile}
                        </span>
                    </div> */}
                        <div className="pos" id="_36:303" style={{ top: 388, left: 146 }}>
                            <span
                                id="_12.9"
                                style={{ fontFamily: "Arial", fontSize: "12.9px", color: "#000000" }}
                            >
                                STEEL<span style={{ fontFamily: "Times New Roman" }}> </span> MARKET
                                <span style={{ fontFamily: "Times New Roman" }}> </span> YARD
                                <span style={{ fontFamily: "Times New Roman" }}> </span>
                            </span>
                        </div>
                        <div className="pos" id="_36:321" style={{ top: 406, left: 146 }}>
                            <span
                                id="_12.9"
                                style={{ fontFamily: "Arial", fontSize: "12.9px", color: "#000000" }}
                            >
                                KALAMBOLI, NAVI<span style={{ fontFamily: "Times New Roman" }}> </span> MUMBAI
                                <span style={{ fontFamily: "Times New Roman" }}> </span> 410218
                            </span>
                        </div>
                        <div className="pos" id="_36:340" style={{ top: 425, left: 146 }}>
                            <span
                                id="_12.9"
                                style={{ fontFamily: "Arial", fontSize: "12.9px", color: "#000000" }}
                            >
                                Mobile: {data.plotOwnerMobile}
                            </span>
                        </div>
                        {/* <div className="pos" id="_103:366" style={{ top: 566, left: 403 }}>
                        <span
                            id="_14.3"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "14.3px",
                                color: "#000000"
                            }}
                        >
                            Sub.
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            :-
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            PAYMENT
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            OF
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            MARKET
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            FEE
                        </span>
                    </div>
                    <div className="pos" id="_103:386" style={{ top: 586, left: 403 }}>
                        <span
                            id="_14.3"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "14.3px",
                                color: "#000000"
                            }}
                        >
                            Ref.
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            :-
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            YOUR
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            WAREHOUSING
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            PLOT
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            NO.
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            321
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            AREA
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            250
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            SQ.
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            MTRS.
                        </span>
                    </div> */}
                        {/* <div className="pos" id="_36:415" style={{ top: 615, left: 336 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            Dear<span style={{ fontFamily: "Times New Roman" }}> </span> Sir,
                        </span>
                    </div>
                    <div className="pos" id="_86:431" style={{ top: 631, left: 386 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            Please<span style={{ fontFamily: "Times New Roman" }}> </span> refere
                            <span style={{ fontFamily: "Times New Roman" }}> </span> to
                            <span style={{ fontFamily: "Times New Roman" }}> </span> our
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Demand
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Notice
                            <span style={{ fontFamily: "Times New Roman" }}> </span> under
                            <span style={{ fontFamily: "Times New Roman" }}> </span> which
                            <span style={{ fontFamily: "Times New Roman" }}> </span> you
                            <span style={{ fontFamily: "Times New Roman" }}> </span> were
                            <span style={{ fontFamily: "Times New Roman" }}> </span> requested
                            <span style={{ fontFamily: "Times New Roman" }}> </span> to
                            <span style={{ fontFamily: "Times New Roman" }}> </span> pay
                            <span style={{ fontFamily: "Times New Roman" }}> </span> the
                            <span style={{ fontFamily: "Times New Roman" }}> </span> arrears
                            <span style={{ fontFamily: "Times New Roman" }}> </span> of
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Market
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Fee
                            <span style={{ fontFamily: "Times New Roman" }}> </span> and
                        </span>
                    </div> */}
                        {/* <div className="pos" id="_36:448" style={{ top: 648, left: 336 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            Delay<span style={{ fontFamily: "Times New Roman" }}> </span> Payment
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Charges,
                            <span style={{ fontFamily: "Times New Roman" }}> </span> as
                            <span style={{ fontFamily: "Times New Roman" }}> </span> shown
                            <span style={{ fontFamily: "Times New Roman" }}> </span> therein
                            <span style={{ fontFamily: "Times New Roman" }}> </span> and
                            <span style={{ fontFamily: "Times New Roman" }}> </span> two
                            <span style={{ fontFamily: "Times New Roman" }}> </span> six
                            <span style={{ fontFamily: "Times New Roman" }}> </span> monthly
                            <span style={{ fontFamily: "Times New Roman" }}> </span>
                            <span style={{ fontFamily: "Times New Roman" }}></span> installment
                            <span style={{ fontFamily: "Times New Roman" }}> </span> of
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Market
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Fee,
                            <span style={{ fontFamily: "Times New Roman" }}> </span> before
                            <span style={{ fontFamily: "Times New Roman" }}> </span> the
                            <span style={{ fontFamily: "Times New Roman" }}> </span> specified
                            <span style={{ fontFamily: "Times New Roman" }}> </span>
                        </span>
                    </div>
                    <div className="pos" id="_36:465" style={{ top: 665, left: 336 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            dates.<span style={{ fontFamily: "Times New Roman" }}> </span> You
                            <span style={{ fontFamily: "Times New Roman" }}> </span> have
                            <span style={{ fontFamily: "Times New Roman" }}> </span> not
                            <span style={{ fontFamily: "Times New Roman" }}> </span> paid
                            <span style={{ fontFamily: "Times New Roman" }}> </span> fully
                            <span style={{ fontFamily: "Times New Roman" }}> </span> the
                            <span style={{ fontFamily: "Times New Roman" }}> </span> arrears
                            <span style={{ fontFamily: "Times New Roman" }}> </span> and/or
                            <span style={{ fontFamily: "Times New Roman" }}> </span> the
                            <span style={{ fontFamily: "Times New Roman" }}> </span> 1st
                            <span style={{ fontFamily: "Times New Roman" }}> </span> instllment
                            <span style={{ fontFamily: "Times New Roman" }}> </span> of
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Market
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Fee
                            <span style={{ fontFamily: "Times New Roman" }}> </span> so
                            <span style={{ fontFamily: "Times New Roman" }}> </span> far.
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Failure
                            <span style={{ fontFamily: "Times New Roman" }}> </span> to
                            <span style={{ fontFamily: "Times New Roman" }}> </span> do
                            <span style={{ fontFamily: "Times New Roman" }}> </span> so
                            <span style={{ fontFamily: "Times New Roman" }}> </span> would
                            <span style={{ fontFamily: "Times New Roman" }}> </span> not
                            <span style={{ fontFamily: "Times New Roman" }}> </span>
                        </span>
                    </div>
                    <div className="pos" id="_36:481" style={{ top: 681, left: 336 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            only<span style={{ fontFamily: "Times New Roman" }}> </span> mean
                            <span style={{ fontFamily: "Times New Roman" }}> </span> higher
                            <span style={{ fontFamily: "Times New Roman" }}> </span> financial
                            <span style={{ fontFamily: "Times New Roman" }}> </span> burden
                            <span style={{ fontFamily: "Times New Roman" }}> </span> but
                            <span style={{ fontFamily: "Times New Roman" }}> </span> also
                            <span style={{ fontFamily: "Times New Roman" }}> </span> coercive
                            <span style={{ fontFamily: "Times New Roman" }}> </span> measure
                            <span style={{ fontFamily: "Times New Roman" }}> </span> by
                            <span style={{ fontFamily: "Times New Roman" }}> </span> attachment
                            <span style={{ fontFamily: "Times New Roman" }}> </span> of
                            <span style={{ fontFamily: "Times New Roman" }}> </span> movable
                            <span style={{ fontFamily: "Times New Roman" }}> </span> and
                            <span style={{ fontFamily: "Times New Roman" }}> </span> immovable
                            <span style={{ fontFamily: "Times New Roman" }}> </span> properties
                            <span style={{ fontFamily: "Times New Roman" }}> </span> of
                            <span style={{ fontFamily: "Times New Roman" }}> </span>
                        </span>
                    </div> */}
                        {/* <div className="pos" id="_36:498" style={{ top: 698, left: 336 }}>
                        <span
                            id="_12.6"
                            style={{ fontFamily: "Arial", fontSize: "12.6px", color: "#000000" }}
                        >
                            defaulters<span style={{ fontFamily: "Times New Roman" }}> </span> U/s
                            <span style={{ fontFamily: "Times New Roman" }}> </span> 73
                            <span style={{ fontFamily: "Times New Roman" }}> </span> of
                            <span style={{ fontFamily: "Times New Roman" }}> </span> the
                            <span style={{ fontFamily: "Times New Roman" }}> </span>
                            <span id="_11.9" style={{ fontWeight: "bold", fontSize: "11.9px" }}>
                                {" "}
                                MUMBAI
                            </span>
                            <span
                                id="_11.9"
                                style={{ fontFamily: "Times New Roman", fontSize: "11.9px" }}
                            >
                                {" "}
                            </span>
                            <span id="_11.9" style={{ fontWeight: "bold", fontSize: "11.9px" }}>
                                {" "}
                                METROPOLITAN
                            </span>
                            <span
                                id="_11.9"
                                style={{ fontFamily: "Times New Roman", fontSize: "11.9px" }}
                            >
                                {" "}
                            </span>
                            <span id="_11.9" style={{ fontWeight: "bold", fontSize: "11.9px" }}>
                                {" "}
                                REGION
                            </span>
                            <span
                                id="_11.9"
                                style={{ fontFamily: "Times New Roman", fontSize: "11.9px" }}
                            >
                                {" "}
                            </span>
                            <span id="_11.9" style={{ fontWeight: "bold", fontSize: "11.9px" }}>
                                {" "}
                                SPECIFIED
                            </span>
                            <span
                                id="_11.9"
                                style={{ fontFamily: "Times New Roman", fontSize: "11.9px" }}
                            >
                                {" "}
                            </span>
                            <span id="_11.9" style={{ fontWeight: "bold", fontSize: "11.9px" }}>
                                {" "}
                                COMMODITIES
                            </span>
                            <span
                                id="_11.9"
                                style={{ fontFamily: "Times New Roman", fontSize: "11.9px" }}
                            >
                                {" "}
                            </span>
                            <span id="_11.9" style={{ fontWeight: "bold", fontSize: "11.9px" }}>
                                {" "}
                                MARKET
                            </span>
                            <span
                                id="_11.9"
                                style={{ fontFamily: "Times New Roman", fontSize: "11.9px" }}
                            >
                                {" "}
                            </span>
                            <span id="_11.9" style={{ fontWeight: "bold", fontSize: "11.9px" }}>
                                {" "}
                                (REGULATION)
                            </span>
                            <span
                                id="_11.9"
                                style={{ fontFamily: "Times New Roman", fontSize: "11.9px" }}
                            >
                                {" "}
                            </span>
                        </span>
                    </div>
                    <div className="pos" id="_36:515" style={{ top: 715, left: 336 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            ACT
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            1983.
                        </span>
                    </div>
                    <div className="pos" id="_54:542" style={{ top: 732, left: 354 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            Six<span style={{ fontFamily: "Times New Roman" }}> </span> Monthly
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Instllments
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Period
                        </span>
                    </div>
                    <div className="pos" id="_487:541" style={{ top: 741, left: 787 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            Amount
                        </span>
                    </div>
                    <div className="pos" id="_579:541" style={{ top: 741, left: 879 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            Last
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            date
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            of
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            Payment
                        </span>
                    </div>
                    <div className="pos" id="_53:568" style={{ top: 758, left: 353 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            From
                        </span>
                    </div>
                    <div className="pos" id="_262:568" style={{ top: 758, left: 562 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            To
                        </span>
                    </div>
                    <div className="pos" id="_510:566" style={{ top: 766, left: 810 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            `
                        </span>
                    </div>
                    <div className="pos" id="_53:590" style={{ top: 790, left: 353 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            Market<span style={{ fontFamily: "Times New Roman" }}> </span> Fee
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Upto
                        </span>
                    </div>
                    <div className="pos" id="_262:589" style={{ top: 789, left: 562 }}>
                        <span
                            id="_11.9"
                            style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                        >
                            
                        </span>
                    </div>
                    <div className="pos" id="_537:591" style={{ top: 791, left: 837 }}>
                        <span
                            id="_12.9"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "12.9px",
                                color: "#000000"
                            }}
                        >
                            0.00
                            <span id="_11.6" style={{ fontSize: "11.6px" }}>
                                {" "}
                                Immediate
                            </span>
                        </span>
                    </div> */}
                        {data.type === "FID" ? 
                        <div>

                        </div> :
                        <div>
                            <div className="pos" id="_53:614" style={{ top: 915, left: 173 }}>
                                <span
                                    id="_11.9"
                                    style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                                >
                                    {/* {data.from_date} */}01/04/2023
                                </span>
                            </div>
                            <div className="pos" id="_53:614" style={{ top: 945, left: 173 }}>
                                <span
                                    id="_11.9"
                                    style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                                >
                                    {/* {data.from_date} */}01/10/2023
                                </span>
                            </div>
                            <div className="pos" id="_262:614" style={{ top: 915, left: 442 }}>
                                <span
                                    id="_11.9"
                                    style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                                >
                                    {/* {data.to_date} */}30/09/2023
                                </span>
                            </div>
                            <div className="pos" id="_262:614" style={{ top: 915, left: 802 }}>
                                <span
                                    id="_11.9"
                                    style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                                >
                                    {/* {data.to_date} */}30/04/2023
                                </span>
                            </div>
                            <div className="pos" id="_262:614" style={{ top: 945, left: 442 }}>
                                <span
                                    id="_11.9"
                                    style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                                >
                                    {/* {data.to_date} */}31/03/2024
                                </span>
                            </div>
                            <div className="pos" id="_262:614" style={{ top: 945, left: 802 }}>
                                <span
                                    id="_11.9"
                                    style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                                >
                                    {/* {data.to_date} */}31/10/2023
                                </span>
                            </div>
                            <div className="pos" id="_515:616" style={{ top: 915, left: 679 }}>
                                <span
                                    id="_12.9"
                                    style={{
                                        fontWeight: "bold",
                                        fontFamily: "Arial",
                                        fontSize: "12.9px",
                                        color: "#000000"
                                    }}
                                >
                                    5625
                                </span>
                            </div>
                            <div className="pos" id="_515:616" style={{ top: 945, left: 679 }}>
                                <span
                                    id="_12.9"
                                    style={{
                                        fontWeight: "bold",
                                        fontFamily: "Arial",
                                        fontSize: "12.9px",
                                        color: "#000000"
                                    }}
                                >
                                    5625
                                </span>
                            </div>
                            <div className="pos" id="_262:614" style={{ top: 968, left: 582 }}>
                                <span
                                    id="_11.9"
                                    style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                                >
                                    {/* {data.to_date} */}March 2024
                                </span>
                            </div>
                            <div className="pos" id="_262:614" style={{ top: 995, left: 592 }}>
                                <span
                                    id="_11.9"
                                    style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                                >
                                    {/* {data.to_date} */}April 2024
                                </span>
                            </div>
                            <div className="pos" id="_515:616" style={{ top: 970, left: 679 }}>
                                <span
                                    id="_12.9"
                                    style={{
                                        fontWeight: "bold",
                                        fontFamily: "Arial",
                                        fontSize: "12.9px",
                                        color: "#000000"
                                    }}
                                >
                                    {data.amt_outstanding_total}
                                </span>
                            </div>
                            <div className="pos" id="_579:612" style={{ top: 995, left: 669 }}>
                                <span
                                    id="_11.9"
                                    style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                                >

                                </span>
                            </div>
                            <div className="pos" id="_522:640" style={{ top: 995, left: 679 }}>
                                <span
                                    id="_12.6"
                                    style={{
                                        fontWeight: "bold",
                                        fontFamily: "Arial",
                                        fontSize: "12.6px",
                                        color: "#000000"
                                    }}
                                >
                                    {data.amt_outstanding_dpc}
                                </span>
                            </div>
                            <div className="pos" id="_515:674" style={{ top: 1024, left: 679 }}>
                                <span
                                    id="_12.9"
                                    style={{
                                        fontWeight: "bold",
                                        fontFamily: "Arial",
                                        fontSize: "12.9px",
                                        color: "#000000"
                                    }}
                                >
                                    {data.finalTotal}
                                </span>
                            </div>
                        </div>}

                        {/* <div className="pos" id="_54:639" style={{ top: 839, left: 354 }}>
                        <span
                            id="_11.9"
                            style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                        >
                            D.<span style={{ fontFamily: "Times New Roman" }}> </span> P.
                            <span style={{ fontFamily: "Times New Roman" }}> </span> C.
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Upto
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Mar-2023(DPC
                            <span style={{ fontFamily: "Times New Roman" }}> </span> will
                            <span style={{ fontFamily: "Times New Roman" }}> </span> increase
                            <span style={{ fontFamily: "Times New Roman" }}> </span> every
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Month)
                        </span>
                    </div>
                    <div className="pos" id="_522:640" style={{ top: 840, left: 822 }}>
                        <span
                            id="_12.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "12.6px",
                                color: "#000000"
                            }}
                        >
                            {data.amt_outstanding_dpc}
                            <span id="_11.2" style={{ fontSize: "11.2px" }}>
                                {" "}
                                Immediate
                            </span>
                        </span>
                    </div>
                    <div className="pos" id="_53:674" style={{ top: 874, left: 353 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            Total
                        </span>
                    </div>
                    <div className="pos" id="_515:674" style={{ top: 874, left: 815 }}>
                        <span
                            id="_12.9"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "12.9px",
                                color: "#000000"
                            }}
                        >
                            {data.finalTotal}
                        </span>
                    </div>
                    <div className="pos" id="_86:706" style={{ top: 906, left: 386 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            Please<span style={{ fontFamily: "Times New Roman" }}> </span> note
                            <span style={{ fontFamily: "Times New Roman" }}> </span> that
                            <span style={{ fontFamily: "Times New Roman" }}> </span> payments
                            <span style={{ fontFamily: "Times New Roman" }}> </span> received
                            <span style={{ fontFamily: "Times New Roman" }}> </span> from
                            <span style={{ fontFamily: "Times New Roman" }}> </span> you
                            <span style={{ fontFamily: "Times New Roman" }}> </span> will
                            <span style={{ fontFamily: "Times New Roman" }}> </span> be
                            <span style={{ fontFamily: "Times New Roman" }}> </span> first
                            <span style={{ fontFamily: "Times New Roman" }}> </span> adjusted
                            <span style={{ fontFamily: "Times New Roman" }}> </span> against
                            <span style={{ fontFamily: "Times New Roman" }}> </span> the
                            <span style={{ fontFamily: "Times New Roman" }}> </span> D
                            <span style={{ fontFamily: "Times New Roman" }}> </span> .P.C.
                            <span style={{ fontFamily: "Times New Roman" }}> </span> if
                            <span style={{ fontFamily: "Times New Roman" }}> </span> any
                            <span style={{ fontFamily: "Times New Roman" }}> </span> and
                            <span style={{ fontFamily: "Times New Roman" }}> </span>
                        </span>
                    </div>
                    <div className="pos" id="_36:723" style={{ top: 923, left: 336 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            thereafter<span style={{ fontFamily: "Times New Roman" }}> </span>{" "}
                            arrears<span style={{ fontFamily: "Times New Roman" }}> </span> of
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Market
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Fee
                            <span style={{ fontFamily: "Times New Roman" }}> </span> and
                            <span style={{ fontFamily: "Times New Roman" }}> </span> lastly
                            <span style={{ fontFamily: "Times New Roman" }}> </span> against
                            <span style={{ fontFamily: "Times New Roman" }}> </span> the
                            <span style={{ fontFamily: "Times New Roman" }}> </span> demand
                            <span style={{ fontFamily: "Times New Roman" }}> </span> for
                            <span style={{ fontFamily: "Times New Roman" }}> </span> the
                            <span style={{ fontFamily: "Times New Roman" }}> </span> year
                            <span style={{ fontFamily: "Times New Roman" }}> </span> {getCurrentFinancialYear()}
                        </span>
                    </div>
                    <div className="pos" id="_36:756" style={{ top: 956, left: 336 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            Note
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            :
                        </span>
                    </div>
                    <div className="pos" id="_36:772" style={{ top: 972, left: 336 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            1. This<span style={{ fontFamily: "Times New Roman" }}> </span> is
                            <span style={{ fontFamily: "Times New Roman" }}> </span> computer
                            <span style={{ fontFamily: "Times New Roman" }}> </span> generated
                            <span style={{ fontFamily: "Times New Roman" }}> </span> reminder
                            <span style={{ fontFamily: "Times New Roman" }}> </span> notice
                            <span style={{ fontFamily: "Times New Roman" }}> </span> hence
                            <span style={{ fontFamily: "Times New Roman" }}> </span> no
                            <span style={{ fontFamily: "Times New Roman" }}> </span> signature
                            <span style={{ fontFamily: "Times New Roman" }}> </span> is
                            <span style={{ fontFamily: "Times New Roman" }}> </span> required.
                        </span>
                    </div>
                    <div className="pos" id="_36:789" style={{ top: 989, left: 336 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            2. You<span style={{ fontFamily: "Times New Roman" }}> </span> are
                            <span style={{ fontFamily: "Times New Roman" }}> </span> requested
                            <span style={{ fontFamily: "Times New Roman" }}> </span> to
                            <span style={{ fontFamily: "Times New Roman" }}> </span> verify
                            <span style={{ fontFamily: "Times New Roman" }}> </span> the
                            <span style={{ fontFamily: "Times New Roman" }}> </span> outstanding
                            <span style={{ fontFamily: "Times New Roman" }}> </span> amount
                            <span style={{ fontFamily: "Times New Roman" }}> </span> at
                            <span style={{ fontFamily: "Times New Roman" }}> </span> your
                            <span style={{ fontFamily: "Times New Roman" }}> </span> end
                            <span style={{ fontFamily: "Times New Roman" }}> </span> .
                            <span style={{ fontFamily: "Times New Roman" }}> </span> In
                            <span style={{ fontFamily: "Times New Roman" }}> </span> case
                            <span style={{ fontFamily: "Times New Roman" }}> </span> of
                            <span style={{ fontFamily: "Times New Roman" }}> </span> any
                            <span style={{ fontFamily: "Times New Roman" }}> </span> discrepancy
                            <span style={{ fontFamily: "Times New Roman" }}> </span> inform
                            <span style={{ fontFamily: "Times New Roman" }}> </span> us
                            <span style={{ fontFamily: "Times New Roman" }}> </span> within
                            <span style={{ fontFamily: "Times New Roman" }}> </span>
                        </span>
                    </div>
                    <div className="pos" id="_50:805" style={{ top: 1005, left: 350 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            one<span style={{ fontFamily: "Times New Roman" }}> </span> month.
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Otherwise
                            <span style={{ fontFamily: "Times New Roman" }}> </span> the
                            <span style={{ fontFamily: "Times New Roman" }}> </span> amounts
                            <span style={{ fontFamily: "Times New Roman" }}> </span> will
                            <span style={{ fontFamily: "Times New Roman" }}> </span> be
                            <span style={{ fontFamily: "Times New Roman" }}> </span> considered
                            <span style={{ fontFamily: "Times New Roman" }}> </span> acceptable
                            <span style={{ fontFamily: "Times New Roman" }}> </span> to
                            <span style={{ fontFamily: "Times New Roman" }}> </span> you
                            <span style={{ fontFamily: "Times New Roman" }}> </span> and
                            <span style={{ fontFamily: "Times New Roman" }}> </span> final.
                        </span>
                    </div>
                    <div className="pos" id="_36:822" style={{ top: 1022, left: 336 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            3. Please<span style={{ fontFamily: "Times New Roman" }}> </span>{" "}
                            register<span style={{ fontFamily: "Times New Roman" }}> </span> your
                            <span style={{ fontFamily: "Times New Roman" }}> </span> valid
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Mobile
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Number
                            <span style={{ fontFamily: "Times New Roman" }}> </span> and
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Email
                            <span style={{ fontFamily: "Times New Roman" }}> </span> Address
                            <span style={{ fontFamily: "Times New Roman" }}> </span> which
                            <span style={{ fontFamily: "Times New Roman" }}> </span> can
                            <span style={{ fontFamily: "Times New Roman" }}> </span> be
                            <span style={{ fontFamily: "Times New Roman" }}> </span> used
                            <span style={{ fontFamily: "Times New Roman" }}> </span> for
                            <span style={{ fontFamily: "Times New Roman" }}> </span> future
                            <span style={{ fontFamily: "Times New Roman" }}> </span> communication
                            <span style={{ fontFamily: "Times New Roman" }}> </span> .
                        </span>
                    </div>
                    <div className="pos" id="_46:849" style={{ top: 1049, left: 346 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            NEFT/RTGS
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            payments
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            are
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            accepted.
                        </span>
                    </div>
                    <div className="pos" id="_46:866" style={{ top: 1066, left: 346 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            NEFT
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            details
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            of
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            MMR
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            Iron
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            and
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            Steel
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            Market
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            Committee
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            :
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>
                        </span>
                    </div>
                    <div className="pos" id="_46:883" style={{ top: 1083, left: 346 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            Bank
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            name
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            -
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            CANARA
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            BANK
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            UNIT
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            II
                        </span>
                    </div>
                    <div className="pos" id="_611:881" style={{ top: 1081, left: 911 }}>
                        <span
                            id="_11.9"
                            style={{ fontFamily: "Arial", fontSize: "11.9px", color: "#000000" }}
                        >
                            Yours<span style={{ fontFamily: "Times New Roman" }}> </span>{" "}
                            Faithfully
                        </span>
                    </div>
                    <div className="pos" id="_46:899" style={{ top: 1099, left: 346 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            Branch
                        </span>
                    </div>
                    <div className="pos" id="_115:899" style={{ top: 1099, left: 415 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            -
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            Kalamboli
                        </span>
                    </div>
                    <div className="pos" id="_46:917" style={{ top: 1117, left: 346 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            A/c
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            no
                        </span>
                    </div>
                    <div className="pos" id="_114:917" style={{ top: 1117, left: 414 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            -
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            52152200002447
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>
                        </span>
                    </div>
                    <div className="pos" id="_46:933" style={{ top: 1133, left: 346 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            IFSC
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            CODE
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            -
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            CNRB0015215
                        </span>
                    </div>
                    <div className="pos" id="_46:950" style={{ top: 1150, left: 346 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            Please<span style={{ fontFamily: "Times New Roman" }}> </span> mention
                            <span style={{ fontFamily: "Times New Roman" }}> </span> plot
                            <span style={{ fontFamily: "Times New Roman" }}> </span> number
                            <span style={{ fontFamily: "Times New Roman" }}> </span> and
                            <span style={{ fontFamily: "Times New Roman" }}> </span> inform
                            <span style={{ fontFamily: "Times New Roman" }}> </span> us
                            <span style={{ fontFamily: "Times New Roman" }}> </span> after
                            <span style={{ fontFamily: "Times New Roman" }}> </span> the
                            <span style={{ fontFamily: "Times New Roman" }}> </span> payment
                            <span style={{ fontFamily: "Times New Roman" }}> </span> by
                            <span style={{ fontFamily: "Times New Roman" }}> </span> email.
                        </span>
                    </div>
                    <div className="pos" id="_46:966" style={{ top: 1166, left: 346 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            You<span style={{ fontFamily: "Times New Roman" }}> </span> can
                            <span style={{ fontFamily: "Times New Roman" }}> </span> pay
                            <span style={{ fontFamily: "Times New Roman" }}> </span> amount
                            <span style={{ fontFamily: "Times New Roman" }}> </span> through
                            <span style={{ fontFamily: "Times New Roman" }}> </span> follwoing
                            <span style={{ fontFamily: "Times New Roman" }}> </span> link
                            <span style={{ fontFamily: "Times New Roman" }}> </span> -
                            <span style={{ fontFamily: "Times New Roman" }}> </span>
                        </span>
                    </div>
                    <div className="pos" id="_46:982" style={{ top: 1182, left: 346 }}>
                        <span
                            id="_12.2"
                            style={{ fontFamily: "Arial", fontSize: "12.2px", color: "#000000" }}
                        >
                            https://www.onlinesbi.com/sbicollect/icollecthome.htm?corpID=917848
                        </span>
                    </div>
                    <div className="pos" id="_586:980" style={{ top: 1180, left: 886 }}>
                        <span
                            id="_11.6"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Arial",
                                fontSize: "11.6px",
                                color: "#000000"
                            }}
                        >
                            Chief
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            Executive
                            <span style={{ fontWeight: "normal", fontFamily: "Times New Roman" }}>
                                {" "}
                            </span>{" "}
                            Officer.
                        </span>
                    </div> */}
                    </nowrap>
                </nobr>
            </div>
        </>
    );
}

export default PrintBill;