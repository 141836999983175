import React from "react";
import Sidebar from "../../Components/SideMenu/Sidebar";
import Styles from '../Dashboard/index.css';
import { Chart } from "react-google-charts";

const Dashboard = () => {
    return (
        <>
            <title>W3.CSS Template</title>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Raleway"
            />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        '\nhtml,body,h1,h2,h3,h4,h5 {font-family: "Raleway", sans-serif}\n'
                }}
            />
            {/* Sidebar/menu */}
            <div className="sideMenu">
                <Sidebar showHide={true} />
            </div>
            {/* Overlay effect when opening sidebar on small screens */}
            <div
                className="w3-overlay w3-hide-large w3-animate-opacity"
                onclick="w3_close()"
                style={{ cursor: "pointer" }}
                title="close side menu"
                id="myOverlay"
            />
            {/* !PAGE CONTENT! */}
            <div className="w3-main" style={{ marginLeft: 300, marginTop: 43 }}>
                {/* Header */}
                <header className="w3-container" style={{ paddingTop: 22 }}>

                </header>
                <div className="w3-row-padding w3-margin-bottom">
                    <div className="w3-third">
                        <div className="w3-container w3-border w3-padding-10">
                            <div className="w3-center">
                                <h4>9999999999999</h4>
                            </div>
                            <div className="w3-clear" />
                            <h4>Total Plots</h4>
                        </div>
                    </div>
                    <div className="w3-third">
                        <div className="w3-container w3-border w3-padding-10">
                            <div className="w3-center">
                                <h5>99</h5>
                            </div>
                            <div className="w3-clear" />
                            <h4>Active Plots</h4>
                        </div>
                    </div>
                    <div className="w3-third">
                        <div className="w3-container w3-border w3-padding-10">
                            <div className="w3-center">
                                <h5>2023-24</h5>
                            </div>
                            <div className="w3-clear" />
                            <h4>Year</h4>
                        </div>
                    </div>
                </div>
                <div className="w3-row-padding w3-margin-bottom">
                    <div className="w3-third">
                        <div className="w3-container w3-border w3-padding-10">
                            <div className="w3-center">
                                <h4>9999999999999</h4>
                            </div>
                            <div className="w3-clear" />
                            <h4>Arrears</h4>
                        </div>
                    </div>
                    <div className="w3-third">
                        <div className="w3-container w3-border w3-padding-10">
                            <div className="w3-center">
                                <h5>99</h5>
                            </div>
                            <div className="w3-clear" />
                            <h4>Curr. Demand</h4>
                        </div>
                    </div>
                    <div className="w3-third">
                        <div className="w3-container w3-border w3-padding-10">
                            <div className="w3-center">
                                <h5>2023-24</h5>
                            </div>
                            <div className="w3-clear" />
                            <h4>Total Recovery</h4>
                        </div>
                    </div>
                </div>
                <div className="w3-row-padding w3-margin-bottom">
                    <div className="w3-quarter">
                        <div className="w3-container w3-border w3-padding-10">
                            <div className="w3-center">
                                <h5>99</h5>
                            </div>
                            <div className="w3-clear" />
                            <h4>Recd. Arrears</h4>
                        </div>
                    </div>
                    <div className="w3-quarter">
                        <div className="w3-container w3-border w3-padding-10">
                            <div className="w3-center">
                                <h5>99</h5>
                            </div>
                            <div className="w3-clear" />
                            <h4>Recd. Curr. Arrears</h4>
                        </div>
                    </div>
                    <div className="w3-quarter">
                        <div className="w3-container w3-border w3-padding-10">
                            <div className="w3-center">
                                <h5>99</h5>
                            </div>
                            <div className="w3-clear" />
                            <h4>Recd. DPC</h4>
                        </div>
                    </div>
                    <div className="w3-quarter">
                        <div className="w3-container w3-border w3-padding-10">
                            <div className="w3-center">
                                <h5>99</h5>
                            </div>
                            <div className="w3-clear" />
                            <h4>Recd. Total</h4>
                        </div>
                    </div>
                </div>
                <h4 style={{ marginTop: 50 }}><strong>Month Wise Recovery</strong></h4>
                <div className="w3-panel" style={{ marginTop: 50 }}>
                    <table class="w3-table-all">
                        <tr>
                            <th>Rcoverer</th>
                            <th>April</th>
                            <th>May</th>
                            <th>June</th>
                            <th>July</th>
                            <th>August</th>
                            <th>September</th>
                            <th>October</th>
                            <th>November</th>
                            <th>December</th>
                            <th>January</th>
                            <th>February</th>
                            <th>March</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                        </tr>
                        <tr>
                            <td>Admin</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                        </tr>
                        <tr>
                            <th>Total</th>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                            <td>10</td>
                            <td>50</td>
                        </tr>
                    </table>
                    <div style={{ marginTop: 50 }}>
                        <Chart
                            chartType="Bar"
                            data={[["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"], [4, 5.5, 4, 5.5, 4, 5.5, 4, 5.5, 4, 5.5, 8, 12]]}
                            width="100%"
                            height="400px"
                            legendToggle
                        />
                    </div>
                </div>
                {/* End page content */}
                

            </div>
        </>
    )
}

export default Dashboard;