import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../Components/SideMenu/Sidebar";
import Styles from '../IssueNotice/index.css';
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "../../Components/ComponentToPrint";
import { useNavigate } from "react-router-dom";
import { getPlotList } from "../../API";

const IssueNotice = () => {
    const navigation = useNavigate();
    const [plotList, setPlotList] = useState([]);
    const onButtonClick = (data) => {
        navigation('/previewpage', { state: { data: data, noticeType: 'MFDPC' } })
    }
    const onLedgerClick = (data) => {
        navigation('/ledger', { state: { data: data } })
    }
    function onAddLedgerClick() {
        navigation('/addledger')
    }
    useEffect(() => {
        getPlotList()
            .then(res => {
                setPlotList(res.Plots)
            })
    }, [])

    function AddPlot() {
        navigation('/addplot')
    }

    return (
        <>
            <title>W3.CSS Template</title>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Raleway"
            />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        '\nhtml,body,h1,h2,h3,h4,h5 {font-family: "Raleway", sans-serif}\n'
                }}
            />
            {/* Sidebar/menu */}
            <div className="sideMenu">
                <Sidebar showHide={true} />
            </div>
            {/* Overlay effect when opening sidebar on small screens */}
            <div
                className="w3-overlay w3-hide-large w3-animate-opacity"
                onclick="w3_close()"
                style={{ cursor: "pointer" }}
                title="close side menu"
                id="myOverlay"
            />
            {/* !PAGE CONTENT! */}
            <div className="w3-main" style={{ marginLeft: 300, marginTop: 43, marginRight: 20 }}>
                {/* Header */}
                <header className="w3-container" style={{ paddingTop: 22 }}>

                </header>
                <input
                    style={{ marginBottom: 30 }}
                    value="Add Plot"
                    type="button"
                    onClick={AddPlot} />
                <table class="w3-table-all">
                    <tr>
                        <th>ID</th>
                        <th>Plot No.</th>
                        <th>Plot Owner</th>
                        <th>Area</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                    {plotList.map((item, index) => {
                        return (
                            <tr>
                                <td>{item.id}</td>
                                <td>{item.plot_number}</td>
                                <td>{item.plot_owner}</td>
                                <td>{item.area}</td>
                                <td>{item.status}</td>
                                <input
                                    style={{ marginRight: 20 }}
                                    value="Generate Notice"
                                    className={"issueNoticeButton"}
                                    type="button"
                                    onClick={() => onButtonClick(item)} />
                                <input
                                    style={{ marginRight: 20 }}
                                    value="Show Ledger"
                                    className={"issueNoticeButton"}
                                    type="button"
                                    onClick={() => onLedgerClick(item)} />
                                <input
                                    style={{ marginRight: 20 }}
                                    value="Add Ledger"
                                    className={"issueNoticeButton"}
                                    type="button"
                                    onClick={onAddLedgerClick} />
                            </tr>
                        )
                    })}
                </table>
                {/* End page content */}
            </div>
        </>
    )
}

export default IssueNotice;