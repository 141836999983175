import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Sidebar from "../../Components/SideMenu/Sidebar";
import { ComponentToPrint } from "../../Components/ComponentToPrint";
import { getFIDLedgers, getLedgers } from "../../API";
import { useLocation } from "react-router-dom";

const PreviewPage = () => {
    const { state } = useLocation();
    const { data, noticeType, type } = state;
    const componentRef = useRef();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('')
    const [totalAmountOutstanding, setTotalAmountOutstanding] = useState('');
    const [totalDPCOutstanding, setTotalDPCOutstanding] = useState('');
    const [finalTotal, setFinalTotal] = useState('');
    const [isLedgerDataAvailable, setIsLedgerDataAvailable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showHideSideBar, setShowHideSideBar] = useState(false);

    const handlePrint = useReactToPrint({
        documentTitle: "Notice_Plot_Number_"+data.plot_number+"_From_"+fromDate+"_To_"+toDate+".pdf",
        content: () => componentRef.current,
    });

    useEffect(() => {
        setLoading(true)
        console.log(data.plot_number)
        console.log(data.noticeType)
        if (data.noticeType === "FID") {
            getFIDLedgers(data.plot_number)
            .then(res => {
                if (res.Ledgers.length === 0) {
                    setIsLedgerDataAvailable(false)
                } else {
                    setIsLedgerDataAvailable(true)
                    setTotalAmountOutstanding(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_total)
                    setTotalDPCOutstanding(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_dpc)
                    setFromDate(res.Ledgers[res.Ledgers.length - 1].from_date)
                    setToDate(res.Ledgers[res.Ledgers.length - 1].to_date)
                    setFinalTotal(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_total) + parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_dpc))
                }
                setLoading(false)
            })
        } else {
            getLedgers(data.plot_number)
            .then(res => {
                if (res.Ledgers.length === 0) {
                    setIsLedgerDataAvailable(false)
                } else {
                    setIsLedgerDataAvailable(true)
                    setTotalAmountOutstanding(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_total)
                    setTotalDPCOutstanding(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_dpc)
                    setFromDate(res.Ledgers[res.Ledgers.length - 1].from_date)
                    setToDate(res.Ledgers[res.Ledgers.length - 1].to_date)
                    setFinalTotal(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_total) + parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_dpc))
                }
                setLoading(false)
            })
        }
        
    }, [])

    return (
        <>
            <title>W3.CSS Template</title>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Raleway"
            />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        '\nhtml,body,h1,h2,h3,h4,h5 {font-family: "Raleway", sans-serif}\n'
                }}
            />
            {/* Sidebar/menu */}
            {/* {showHideSideBar === true ?
            <div className="sideMenu">
                <Sidebar showHide={true} />
            </div> : null} */}
            {/* <div className="sideMenu">
                <Sidebar showHide={true} />
            </div> */}
            {loading ?
                <h4>Loading ...</h4> :
                <div>
                    {isLedgerDataAvailable ?
                        <div style={{  marginTop: 43, alignItems: 'center', justifyContent: 'center' }}>
                            <input
                                value="Print"
                                type="button"
                                onClick={() => {
                                    handlePrint()
                                    setTimeout(() => {
                                        setShowHideSideBar(true)
                                    }, 2000)
                                }} />
                            <ComponentToPrint ref={componentRef} props={{
                                ref: componentRef,
                                from_date: fromDate,
                                to_date: toDate,
                                amt_outstanding_total: totalAmountOutstanding,
                                amt_outstanding_dpc: totalDPCOutstanding,
                                finalTotal: finalTotal,
                                plot_number: data.plot_number,
                                plotOwnerName: data.plot_owner,
                                plotOwnerArea: data.area,
                                plotOwnerMobile: data.plot_owner_mobile,
                                type: type
                            }} />
                        </div> :
                        <h4>No Ledger Data Available</h4>}
                </div>
            }
        </>

    )
}

export default PreviewPage;