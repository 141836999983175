import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/SideMenu/Sidebar";
import { Space, Select } from "antd";
import ReactDatePicker from "react-datepicker";
import Styles from '../AddReceipt/index.css';
import { useLocation, useNavigate } from "react-router-dom";
import { addFIDReceiptLedgerEntry, addOtherReceipt, addReceipt, addReceiptLedgerEntry, getFIDLedgers, getLastReceiptNumber, getLedgers, getReceipts } from "../../API";

const AddReceipt = () => {
    const navigation = useNavigate();
    const { state } = useLocation();
    const { data, typeSelected } = state;
    const [receiptDate, setReceiptDate] = useState(new Date().toISOString().substring(0, 10));
    const [effectDate, setEffectDate] = useState(new Date().toISOString().substring(0, 10));
    const [receiptNumber, setReceiptNumber] = useState(0);
    const [receiptNumberError, setReceiptNumberError] = useState('');
    const [amount, setAmount] = useState('');
    const [amountError, setAmountError] = useState('');
    const [type, setType] = useState(typeSelected);
    const [typeError, setTypeError] = useState('');
    const [mode, setMode] = useState('');
    const [chequeNEFTNumber, setChequeNEFTNumber] = useState('');
    const [chequeNEFTNumberError, setChequeNEFTNumberError] = useState('');
    const [modeError, setModeError] = useState('');
    const [prepareBy, setPrepareBy] = useState('KVK');
    const [prepareByError, setPrepareByError] = useState('');
    const [bankCharges, setBankCharges] = useState('');
    const [bankChargesError, setBankChargesError] = useState('');
    const [surcharges, setSurcharges] = useState('');
    const [surchargesError, setSurchargesError] = useState('');
    const [mfDPC, setMfDPC] = useState('');
    const [mfDPCError, setMfDPCError] = useState('');
    const [duesClearUpto, setDuesClearUpto] = useState('');
    const [duesClearUptoError, setDuesClearUptoError] = useState('');
    const [remarks, setRemarks] = useState('');
    const [remarksError, setRemarksError] = useState('');
    const [receivedFrom, setReceivedFrom] = useState('');
    const [receivedFromError, setReceivedFromError] = useState('');
    const [plotNumber, setPlotNumber] = useState(data.plot_number);
    const [plotNumberError, setPlotNumberError] = useState('');
    const [calculatedAmount, setCalculatedAmount] = useState('');
    const [calculatedAmountError, setCalculatedAmountError] = useState('');
    const [selectedReceiptHead, setSelectedReceiptHead] = useState('');

    const [party, setParty] = useState(data.plot_owner);
    const [plotArea, setPlotArea] = useState(data.area);
    const [mfDue, setMFDue] = useState('');
    const [dpcDue, setDPCDue] = useState('');
    const [totalDue, setTotalDue] = useState('')
    const [lastEntryID, setLastEntryID] = useState('')
    const [lastFIDEntryID, setLastFIDEntryID] = useState('')
    const [mfDueFID, setMFDueFID] = useState('');
    const [dpcDueFID, setDPCDueFID] = useState('');
    const [totalDueFID, setTotalDueFID] = useState('')
    const [showSaveBtn, setShowBtn] = useState(false)

    useEffect(() => {
        //var tempRandomNumber = random(10000000, 99999999)
        getLastReceiptNumber()
            .then(res => {
                if (res.Receipts.length === 0) {
                    setReceiptNumber("00001");
                } else {
                    var tempReceiptNumber = parseInt(res.Receipts[0].receipt_number) + 1;
                    if (tempReceiptNumber.toString().length === 1) {
                        setReceiptNumber("0000" + tempReceiptNumber)
                    } else if (tempReceiptNumber.toString().length === 2) {
                        setReceiptNumber("000" + tempReceiptNumber)
                    } else if (tempReceiptNumber.toString().length === 3) {
                        setReceiptNumber("00" + tempReceiptNumber)
                    } else if (tempReceiptNumber.toString().length === 4) {
                        setReceiptNumber("0" + tempReceiptNumber)
                    } else if (tempReceiptNumber.toString().length === 5) {
                        setReceiptNumber(tempReceiptNumber)
                    }

                }
                fetchLedgers()
            })
    }, [])

    // useEffect(() => {
    //     calculateTotalBankCharges()
    // }, [bankCharges])

    // useEffect(() => {
    //     calculateSurCharges()
    // }, [surcharges])

    const fetchLedgers = () => {
        if (typeSelected === "FID") {
            // getFIDLedgers(data.plot_number)
            //     .then(res => {
            //         setLastEntryID(res.Ledgers[res.Ledgers.length - 1].id)
            //         setMFDue(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf)
            //         setDPCDue(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_dpc)
            //         setTotalDue(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_total)
            //     })
        } else if (typeSelected === "MFDPC") {
            getLedgers(data.plot_number)
                .then(res => {
                    setLastEntryID(res.Ledgers[res.Ledgers.length - 1].id)
                    setMFDue(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf)
                    setDPCDue(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_dpc)
                    setTotalDue(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_total)
                })
        } else {

        }
    }

    function saveButtonClick() {
        checkForm()
    }

    const random = (min, max) => {
        return Math.floor((Math.random()) * (max - min + 1)) + min;
    }

    const checkForm = () => {
        if (receivedFrom === "") {
            setReceivedFromError("Please enter received from")
        } else if (amount === "") {
            setAmountError("Please enter receipt amount")
        } else if (mode === "") {
            setModeError("Please enter receipt mode")
        } else if (remarks === "") {
            setRemarksError("Please enter remarks")
        } else if ((mode != "CASH" || mode != "") && chequeNEFTNumber.length < 5) {
            setChequeNEFTNumberError("Please enter proper number.")
        } else {
            console.log('API Called')
            if (typeSelected === "Other") {
                let newDate = new Date();
                let date = newDate.getDate();
                if (date < 10) {
                    date = "0" + date;
                } else {

                }
                let month = newDate.getMonth() + 1;
                if (month < 10) {
                    month = "0" + month;
                } else {

                }
                let year = newDate.getFullYear();
                let finaldate = date + "/" + month + "/" + year;
                addOtherReceipt(typeSelected === "MFDPC" ? plotNumber : "", receiptNumber, finaldate, amount, receivedFrom, selectedReceiptHead, typeSelected, mode, chequeNEFTNumber, prepareBy, "Clear", bankCharges, surcharges, mfDPC, duesClearUpto, remarks)
                    .then(res => {
                        var tempOutstandingMF = parseInt(mfDue) - parseInt(amount);
                        var tempOutstandingTotal = parseInt(totalDue) - parseInt(amount);
                        if (tempOutstandingMF < 0) {
                            tempOutstandingMF = 0;
                            tempOutstandingTotal = 0;
                        }
                        // if (typeSelected === "MFDPC") {
                        //     addReceiptLedgerEntry(lastEntryID, calculatedAmount, tempOutstandingMF, tempOutstandingTotal, receiptNumber + "-" + receiptDate)
                        //         .then(res => {
                        //             alert('Receipt added to ledger successfully.')
                        //             navigation('/mfdpclist', { state: { data: data } })
                        //         })
                        // } else {

                        //     //navigation('/mfdpclist', { state: { data: data } })
                        // }
                        alert('Receipt generated successfully.')
                        navigation('/mfdpclist', { state: { data: data } })
                    })
            } else {
                let newDate = new Date();
                let date = newDate.getDate();
                if (date < 10) {
                    date = "0" + date;
                } else {

                }
                let month = newDate.getMonth() + 1;
                if (month < 10) {
                    month = "0" + month;
                } else {

                }
                let year = newDate.getFullYear();
                let finaldate = date + "/" + month + "/" + year;
                addReceipt(typeSelected === "MFDPC" || typeSelected === "FID" || typeSelected === "Other" ? plotNumber : "", receiptNumber, finaldate, amount, receivedFrom, typeSelected, mode, chequeNEFTNumber, prepareBy, "Clear", bankCharges, surcharges, mfDPC, duesClearUpto, remarks)
                    .then(res => {
                        var tempOutstandingMF = parseInt(mfDue) - parseInt(amount);
                        var tempOutstandingTotal = parseInt(totalDue) - parseInt(amount);
                        if (tempOutstandingMF < 0) {
                            tempOutstandingMF = 0;
                            tempOutstandingTotal = 0;
                        }
                        if (typeSelected === "MFDPC") {
                            addReceiptLedgerEntry(lastEntryID, calculatedAmount, tempOutstandingMF, tempOutstandingTotal, receiptNumber + "-" + receiptDate)
                                .then(res => {
                                    alert('Receipt added to ledger successfully.')
                                    navigation('/mfdpclist', { state: { data: data } })
                                })
                        } else if (typeSelected === "FID") {
                            addFIDReceiptLedgerEntry(lastEntryID, calculatedAmount, tempOutstandingMF, tempOutstandingTotal, receiptNumber + "-" + receiptDate)
                                .then(res => {
                                    alert('Receipt added to FID ledger successfully.')
                                    navigation('/mfdpclist', { state: { data: data } })
                                })
                        } else {
                            alert('Receipt generated successfully.')
                            navigation('/mfdpclist', { state: { data: data } })
                            //navigation('/mfdpclist', { state: { data: data } })
                        }
                    })
            }

        }
    }

    const calculateTotal = () => {
        if (amount === '') {
            setCalculatedAmount(0)
            setShowBtn(true)
        } else {
            var tempTotal = 0;
            tempTotal = parseInt(amount);
            if (dpcDue === '' && bankCharges === '' && surcharges === '') {
                tempTotal = parseInt(amount)
            } else if (dpcDue != '' && bankCharges === '' && surcharges === '') {
                tempTotal = tempTotal - parseInt(dpcDue)
            } else if (dpcDue === '' && bankCharges != '' && surcharges === '') {
                tempTotal = tempTotal - parseInt(bankCharges)
            } else if (dpcDue === '' && bankCharges === '' && surcharges != '') {
                tempTotal = tempTotal - parseInt(surcharges)
            } else if (dpcDue != '' && bankCharges != '' && surcharges === '') {
                tempTotal = tempTotal - parseInt(dpcDue) - parseInt(bankCharges)
            } else if (dpcDue === '' && bankCharges != '' && surcharges != '') {
                tempTotal = tempTotal - parseInt(surcharges) - parseInt(bankCharges)
            } else if (dpcDue != '' && bankCharges === '' && surcharges != '') {
                tempTotal = tempTotal - parseInt(dpcDue) - parseInt(surcharges)
            } else if (dpcDue != '' && bankCharges != '' && surcharges != '') {
                tempTotal = tempTotal - parseInt(dpcDue) - parseInt(bankCharges) - parseInt(surcharges)
            } else {

            }
            setCalculatedAmount(tempTotal)
            setShowBtn(true)
        }
    }

    return (
        <>
            <title>W3.CSS Template</title>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Raleway"
            />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        '\nhtml,body,h1,h2,h3,h4,h5 {font-family: "Raleway", sans-serif}\n'
                }}
            />
            {/* Sidebar/menu */}
            {/* <div className="sideMenu">
                <Sidebar showHide={false} />
            </div> */}
            {/* Overlay effect when opening sidebar on small screens */}
            <div
                className="w3-overlay w3-hide-large w3-animate-opacity"
                onclick="w3_close()"
                style={{ cursor: "pointer" }}
                title="close side menu"
                id="myOverlay"
            />
            <div className="w3-main" style={{ marginLeft: 100, width: 400 }}>
                <h2>Receipt</h2>
                <div className={"inputContainer"}>
                    <div className={"w3-row"}>
                        <Space direction="horizontal">
                            <div>
                                <p>Receipt Date</p>
                                <ReactDatePicker className={"inputBox"} locale="es" selected={receiptDate} onChange={(date) => setReceiptDate(date.toISOString().substring(0, 10))} />
                            </div>
                            <div>
                                <p>Effect Date</p>
                                <ReactDatePicker className={"inputBox"} locale="es" selected={effectDate} onChange={(date) => setEffectDate(date.toISOString().substring(0, 10))} />
                            </div>
                            <div>
                                <p>Plot Number</p>
                                <input
                                    value={plotNumber}
                                    placeholder="Enter Plot Number"
                                    onChange={ev => setPlotNumber(ev.target.value)}
                                    className={"inputBox"} />
                                <label className="errorLabel">{plotNumberError}</label>
                            </div>
                        </Space>
                    </div>
                    <div>
                        <p>Received From</p>
                        <input
                            value={receivedFrom}
                            placeholder="Enter Received Number"
                            onChange={ev => setReceivedFrom(ev.target.value)}
                            className={"inputBox"} />
                        <label className="errorLabel">{receivedFromError}</label>
                    </div>
                    {typeSelected === "MFDPC" ?
                        <div>
                            <Space direction="horizontal">
                                <div>
                                    <p>Party</p>
                                    <input
                                        value={party}
                                        readOnly
                                        style={{ backgroundColor: 'lightgrey' }}
                                        onChange={ev => setParty(ev.target.value)}
                                        className={"inputBox"} />
                                </div>
                                <div>
                                    <p>Plot Area</p>
                                    <input
                                        value={plotArea}
                                        readOnly
                                        style={{ backgroundColor: 'lightgrey' }}
                                        onChange={ev => setPlotArea(ev.target.value)}
                                        className={"inputBox"} />
                                </div>
                            </Space>
                        </div> : null}
                    {typeSelected === "MFDPC" || typeSelected === "FID" ?
                        <div>
                            <Space direction="horizontal">
                                <div>
                                    <p>MF Due</p>
                                    <input
                                        value={mfDue}
                                        readOnly
                                        style={{ backgroundColor: 'lightgrey' }}
                                        onChange={ev => setMFDue(ev.target.value)}
                                        className={"inputBox"} />
                                </div>
                                <div>
                                    <p>DPC Due</p>
                                    <input
                                        value={dpcDue}
                                        readOnly
                                        style={{ backgroundColor: 'lightgrey' }}
                                        onChange={ev => setDPCDue(ev.target.value)}
                                        className={"inputBox"} />
                                </div>
                                <div>
                                    <p>Total Due</p>
                                    <input
                                        value={totalDue}
                                        readOnly
                                        style={{ backgroundColor: 'lightgrey' }}
                                        onChange={ev => setTotalDue(ev.target.value)}
                                        className={"inputBox"} />
                                </div>
                            </Space>
                        </div> : null}
                    {typeSelected === "Other" ?
                        <div>
                            <Space direction="horizontal">
                                <div>
                                    <p>Receipt Head</p>
                                    {/* <input
                                    value={mode}
                                    onChange={ev => setMode(ev.target.value)}
                                    className={"inputBox"} /> */}
                                    <Select
                                        className={"inputBox"}
                                        placeholder="Select Mode"
                                        onChange={(value) => {
                                            setSelectedReceiptHead(value)
                                        }}
                                        // filterOption={(input, option) => {
                                        //     setMode(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        // }}
                                        options={[
                                            {
                                                value: 'Entry Fee / Halting Charges',
                                                label: 'Entry Fee / Halting Charges'
                                            },
                                            {
                                                value: 'Sale Form Fee',
                                                label: 'Sale Form Fee'
                                            },
                                            {
                                                value: 'License Fee',
                                                label: 'License Fee'
                                            },
                                            {
                                                value: 'Registration Fee',
                                                label: 'Registration Fee'
                                            },
                                            {
                                                value: 'License Renewal Fee',
                                                label: 'License Renewal Fee'
                                            },
                                            {
                                                value: 'Reinstantment Charges',
                                                label: 'Reinstantment Charges'
                                            },
                                            {
                                                value: 'NOC Charges',
                                                label: 'NOC Charges'
                                            },
                                            {
                                                value: 'Security Deposit',
                                                label: 'Security Deposit'
                                            },
                                            {
                                                value: 'EMD',
                                                label: 'EMD'
                                            },
                                            {
                                                value: 'Tender Form Fee',
                                                label: 'Tender Form Fee'
                                            },
                                            {
                                                value: 'Tender Fee',
                                                label: 'Tender Fee'
                                            },
                                            {
                                                value: 'Normanative Charges',
                                                label: 'Normanative Charges'
                                            },
                                            {
                                                value: 'Supervision Charges',
                                                label: 'Supervision Charges'
                                            },
                                            {
                                                value: 'CGST',
                                                label: 'CGST'
                                            },
                                            {
                                                value: 'SGST',
                                                label: 'SGST'
                                            },
                                            {
                                                value: 'TCS',
                                                label: 'TCS'
                                            },
                                            {
                                                value: 'TDS',
                                                label: 'TDS'
                                            },
                                            {
                                                value: 'HORDING AND ADVERTISEMENT',
                                                label: 'HORDING AND ADVERTISEMENT'
                                            },
                                            {
                                                value: 'ENCROACHMENT PENALTY',
                                                label: 'ENCROACHMENT PENALTY'
                                            },
                                            {
                                                value: 'SCRAP SALE',
                                                label: 'SCRAP SALE'
                                            },
                                            {
                                                value: 'Bank Charges Received',
                                                label: 'Bank Charges Received'
                                            },
                                            {
                                                value: 'Surcharges Received',
                                                label: 'Surcharges Received'
                                            },
                                            {
                                                value: 'MISC Receipt',
                                                label: 'MISC Receipt'
                                            },
                                            {
                                                value: 'TDS/TCS Received',
                                                label: 'TDS/TCS Received'
                                            },
                                            {
                                                value: 'RIGHT TO INFORMATION ACT (RTI) FEES',
                                                label: 'RIGHT TO INFORMATION ACT (RTI) FEES'
                                            },
                                            {
                                                value: 'ELECTION FORM FEE',
                                                label: 'ELECTION FORM FEE'
                                            },
                                            {
                                                value: 'PARKING CHARGES',
                                                label: 'PARKING CHARGES'
                                            },
                                            {
                                                value: 'EXTRA CULVERT CHARGES',
                                                label: 'EXTRA CULVERT CHARGES'
                                            },
                                            {
                                                value: 'PLOT UTILIZATION CHARGES',
                                                label: 'PLOT UTILIZATION CHARGES'
                                            }
                                        ]} />
                                    <label className="errorLabel">{modeError}</label>
                                </div>
                                <div>
                                    <p>Receipt Amount</p>
                                    <input
                                        value={amount}
                                        onChange={ev => {
                                            setAmount(ev.target.value)
                                            setShowBtn(false)
                                        }}
                                        className={"inputBox"} />
                                    <label className="errorLabel">{amountError}</label>
                                </div>
                            </Space>
                        </div> : null}
                    <div>
                        <Space direction="horizontal">
                            <div>
                                <p>Receipt Type</p>
                                <input
                                    value={typeSelected}
                                    readOnly
                                    style={{ backgroundColor: 'lightgrey' }}
                                    className={"inputBox"} />
                                <label className="errorLabel">{typeError}</label>
                            </div>
                            <div>
                                <p>Receipt Amount</p>
                                <input
                                    value={amount}
                                    onChange={ev => {
                                        setAmount(ev.target.value)
                                        setShowBtn(false)
                                    }}
                                    readOnly={typeSelected === "Other" ? true : false}
                                    className={"inputBox"} />
                                <label className="errorLabel">{amountError}</label>
                            </div>
                            <div>
                                <p>Receipt Mode</p>
                                {/* <input
                                    value={mode}
                                    onChange={ev => setMode(ev.target.value)}
                                    className={"inputBox"} /> */}
                                <Select
                                    className={"inputBox"}
                                    placeholder="Select Mode"
                                    onChange={(value) => {
                                        setMode(value)
                                    }}
                                    // filterOption={(input, option) => {
                                    //     setMode(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    // }}
                                    options={[
                                        {
                                            value: 'CASH',
                                            label: 'CASH'
                                        },
                                        {
                                            value: 'Cheque',
                                            label: 'Cheque'
                                        },
                                        {
                                            value: 'Demand Draft',
                                            label: 'Demand Draft'
                                        },
                                        {
                                            value: 'NEFT/RTGS',
                                            label: 'NEFT/RTGS'
                                        }
                                    ]} />
                                <label className="errorLabel">{modeError}</label>
                            </div>
                        </Space>
                    </div>
                    {mode === "CASH" || mode === '' ? null :
                    <div>
                        <p>Cheque Number/ Demand Draft Number/ NEFT ID</p>
                        <input
                            value={chequeNEFTNumber}
                            onChange={ev => {
                                setChequeNEFTNumber(ev.target.value)
                                setShowBtn(false)
                            }}
                            className={"inputBox"} />
                        <label className="errorLabel">{chequeNEFTNumberError}</label>
                    </div>}
                    {typeSelected === "MFDPC" ?
                        <div>
                            <Space direction="horizontal">
                                <div>
                                    <p>Bank Charges</p>
                                    <input
                                        value={bankCharges}
                                        onChange={ev => {
                                            setBankCharges(ev.target.value)
                                            setShowBtn(false)
                                        }}
                                        className={"inputBox"} />
                                    <label className="errorLabel">{bankChargesError}</label>
                                </div>
                                <div>
                                    <p>Surcharges</p>
                                    <input
                                        value={surcharges}
                                        onChange={ev => {
                                            setSurcharges(ev.target.value)
                                            setShowBtn(false)
                                        }}
                                        className={"inputBox"} />
                                    <label className="errorLabel">{surchargesError}</label>
                                </div>
                                <div>
                                    <p>MF / DPC</p>
                                    <input
                                        value={mfDPC}
                                        readOnly
                                        style={{ backgroundColor: 'lightgrey' }}
                                        onChange={ev => setMfDPC(ev.target.value)}
                                        className={"inputBox"} />
                                    <label className="errorLabel">{mfDPCError}</label>
                                </div>
                            </Space>
                        </div> : null}
                    <Space direction="horizontal">
                        <div>
                            <p>Calculated Total</p>
                            <input
                                value={calculatedAmount}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                placeholder="Calculated Total"
                                className={"inputBox"} />
                            <label className="errorLabel">{calculatedAmountError}</label>
                        </div>
                        {typeSelected === "MFDPC" ?
                            <div>
                                <p>Dues Clear Upto</p>
                                <input
                                    value={duesClearUpto}
                                    placeholder="Enter Dues Clear Upto"
                                    onChange={ev => setDuesClearUpto(ev.target.value)}
                                    className={"inputBox"} />
                                <label className="errorLabel">{duesClearUptoError}</label>
                            </div> : null}
                        <div>
                            <p>Remarks</p>
                            <input
                                value={remarks}
                                placeholder="Enter Remarks"
                                onChange={ev => setRemarks(ev.target.value)}
                                className={"inputBox"} />
                            <label className="errorLabel">{remarksError}</label>
                        </div>
                    </Space>
                </div>
                <Space direction="horizontal">
                    <div className={"inputContainer"} style={{ margin: 30 }}>
                        <input
                            value="Calculate"
                            className={"inputButton"}
                            type="button"
                            onClick={() => {
                                calculateTotal()
                            }}
                        />
                    </div>
                    {showSaveBtn ?
                        <div className={"inputContainer"} style={{ marginLeft: 130 }}>
                            <input
                                value="Save"
                                className={"inputButton"}
                                type="button"
                                onClick={saveButtonClick}
                            />
                        </div> : null}
                </Space>

            </div>
        </>
    )
}

export default AddReceipt;