import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Sidebar from "../../Components/SideMenu/Sidebar";
import { getLedgers } from "../../API";
import { useLocation } from "react-router-dom";
import { ComponentToReceipt } from "../../Components/ComponentToReceipt";

const ReceiptPreviewPage = () => {
    const { state } = useLocation();
    const { data, receiptType } = state;
    const componentRef = useRef();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('')
    const [totalAmountOutstanding, setTotalAmountOutstanding] = useState('');
    const [totalDPCOutstanding, setTotalDPCOutstanding] = useState('');
    const [finalTotal, setFinalTotal] = useState('');
    const [isLedgerDataAvailable, setIsLedgerDataAvailable] = useState(false)
    const [loading, setLoading] = useState(false)

    const handlePrint = useReactToPrint({
        documentTitle: receiptType+"_Receipt_"+data.receipt_number+".pdf",
        content: () => componentRef.current,
    });

    useEffect(() => {
        setLoading(true)
        getLedgers(data.plot_number)
            .then(res => {
                if (res.Ledgers.length === 0) {
                    setIsLedgerDataAvailable(false)
                } else {
                    setIsLedgerDataAvailable(true)
                    setTotalAmountOutstanding(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_total)
                    setTotalDPCOutstanding(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_dpc)
                    setFromDate(res.Ledgers[res.Ledgers.length - 1].from_date)
                    setToDate(res.Ledgers[res.Ledgers.length - 1].to_date)
                    setFinalTotal(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_total) + parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_dpc))
                }
                setLoading(false)
            })
    }, [])

    return (
        <>
            <title>W3.CSS Template</title>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Raleway"
            />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        '\nhtml,body,h1,h2,h3,h4,h5 {font-family: "Raleway", sans-serif}\n'
                }}
            />
            {/* Sidebar/menu */}
            {/* <div className="sideMenu">
                <Sidebar showHide={true} />
            </div> */}
            {loading ?
                <h4>Loading ...</h4> :
                <div>
                    {receiptType === "Suspense/Other" ?
                        <div style={{ marginLeft: 350, marginTop: 43, alignItems: 'center', justifyContent: 'center' }}>
                            <input
                                value="Print"
                                type="button"
                                onClick={handlePrint} />
                            <ComponentToReceipt ref={componentRef} props={{
                                ref: componentRef,
                                receiptNumber: data.receipt_number,
                                receiptDate: data.receipt_date,
                                receiptAmount: data.amount,
                                plotNumber: data.plot_number,
                                receiptType: receiptType,
                                receivedFrom: data.received_from,
                                receiptMode: data.mode,
                                receiptChequeNumber: data.cheque_neft_number
                            }} />
                        </div> :
                        <div>
                            {isLedgerDataAvailable ?
                                <div style={{ marginLeft: 350, marginTop: 43, alignItems: 'center', justifyContent: 'center' }}>
                                    <input
                                        value="Print"
                                        type="button"
                                        onClick={handlePrint} />
                                    <ComponentToReceipt ref={componentRef} props={{
                                        ref: componentRef,
                                        receiptNumber: data.receipt_number,
                                        receiptDate: data.receipt_date,
                                        receiptAmount: data.amount,
                                        plotNumber: data.plot_number,
                                        receiptType: receiptType,
                                        receivedFrom: data.received_from,
                                        receiptMode: data.mode,
                                        receiptChequeNumber: data.cheque_neft_number
                                    }} />
                                </div> :
                                <h4>No Ledger Data Available</h4>}
                        </div>}
                </div>
            }
        </>

    )
}

export default ReceiptPreviewPage;