import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../Components/SideMenu/Sidebar";
import Styles from '../IssueNotice/index.css';
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "../../Components/ComponentToPrint";
import { useLocation, useNavigate } from "react-router-dom";
import { getOtherReceipts, getPlotList, getReceipts, getSuspenseReceipts } from "../../API";
import { Modal, Space, Select, Button } from "antd";

const OtherReceiptList = () => {
    // const {state} = useLocation();
    // const { data } = state;
    const navigation = useNavigate();
    const [receiptList, setReceiptList] = useState([]);
    const [filterReceiptList, setFilterReceiptList] = useState([]);
    const [searchPlot, setSearchPlot] = useState('')
    const [searchPlotMobile, setSearchPlotMobile] = useState('')
    const [searchPlotNumber, setSearchPlotNumber] = useState('')
    const [showReceiptTypeModal, setShowReceiptTypeModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedReceiptType, setSelectedReceiptType] = useState('Suspense')

    const onButtonClick = (data) => {
        navigation('/receiptpreviewpage', { state: { data: data, receiptType: 'Suspense/Other' } })
    }

    const navigateToEditPlotList = (id, amount) => {
        navigation('/editreceiptplotlist',{ state: { id: id, amount: amount } })
    }
    // const onLedgerClick = (data) => {
    //     navigation('/ledger', { state: { data: data } })
    // }
    // function onAddLedgerClick(data) {
    //     navigation('/addreceipt', { state: { data: data } })
    // }
    useEffect(() => {
        setLoading(true)
        getSuspenseReceipts()
            .then(res => {
                setReceiptList(res.Receipts)
                setFilterReceiptList(res.Receipts)
            })
        setLoading(false)
    }, [])

    const loadOtherReceipts = () => {
        setLoading(true)
        getOtherReceipts()
        .then(res => {
            setReceiptList(res.Receipts)
            setFilterReceiptList(res.Receipts)
        })
        setLoading(false)
    }

    const loadSuspenseReceipts = () => {
        setLoading(true)
        getSuspenseReceipts()
        .then(res => {
            setReceiptList(res.Receipts)
            setFilterReceiptList(res.Receipts)
        })
        setLoading(false)
    }

    function AddPlot() {
        navigation('/addplot')
    }

    const searchText = (e) => {
        let text = e.toLowerCase()
        let trucks = receiptList
        let filteredName = receiptList.filter((item) => {
            return item.plot_owner.toLowerCase().match(text)
        })
        if (!text || text === '') {
            setFilterReceiptList(receiptList);
        } else if (!Array.isArray(filteredName) && !filteredName.length) {

        } else if (Array.isArray(filteredName)) {
            setFilterReceiptList(filteredName)
        }
    }

    const searchMobileText = (e) => {
        let text = e.toLowerCase()
        let trucks = receiptList
        let filteredName = receiptList.filter((item) => {
            return item.plot_owner_mobile.toLowerCase().match(text)
        })
        if (!text || text === '') {
            setFilterReceiptList(receiptList);
        } else if (!Array.isArray(filteredName) && !filteredName.length) {

        } else if (Array.isArray(filteredName)) {
            setFilterReceiptList(filteredName)
        }
    }

    const searchPlotNumberText = (e) => {
        let text = e.toLowerCase()
        let trucks = receiptList
        let filteredName = receiptList.filter((item) => {
            return item.plot_number.toLowerCase().match(text)
        })
        if (!text || text === '') {
            setFilterReceiptList(receiptList);
        } else if (!Array.isArray(filteredName) && !filteredName.length) {

        } else if (Array.isArray(filteredName)) {
            setFilterReceiptList(filteredName)
        }
    }

    return (
        <>
            <title>W3.CSS Template</title>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Raleway"
            />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        '\nhtml,body,h1,h2,h3,h4,h5 {font-family: "Raleway", sans-serif}\n'
                }}
            />
            {/* Sidebar/menu */}
            <div className="sideMenu">
                <Sidebar showHide={true} />
            </div>
            {/* Overlay effect when opening sidebar on small screens */}
            <div
                className="w3-overlay w3-hide-large w3-animate-opacity"
                onclick="w3_close()"
                style={{ cursor: "pointer" }}
                title="close side menu"
                id="myOverlay"
            />
            {/* <h2>Receipts</h2> */}
            {/* !PAGE CONTENT! */}
            {loading ? <p>Loading ...</p> :
                <div className="w3-main" style={{ marginLeft: 300, marginRight: 20 }}>
                    {/* Header */}
                    <header className="w3-container" style={{ paddingTop: 22 }}>

                    </header>
                    {/* <input
                    style={{ marginBottom: 30 }}
                    value="Add Plot"
                    type="button"
                    onClick={AddPlot} /> */}
                    {/* <div id="container">
                    <div id="inner">
                        <Space direction="horizontal">
                            <div class="child">
                                <p>Search By Plot Number</p>
                                <input
                                    style={{ marginBottom: 30 }}
                                    value={searchPlotNumber}
                                    placeholder="Enter Plot Number"
                                    onChange={ev => {
                                        setSearchPlotNumber(ev.target.value)
                                        searchPlotNumberText(ev.target.value)
                                    }}
                                    className={"inputBox"} />
                            </div>
                        </Space>
                    </div>
                </div> */}
                    <Space direction="horizontal" style={{ marginBottom: 20 }}>
                        <Button
                            onClick={() => {
                                setSelectedReceiptType("Suspense")
                                loadSuspenseReceipts()
                            }}
                            style={{
                                backgroundColor: selectedReceiptType === "Suspense" ? 'white' : 'silver'
                            }}>
                            Suspense Receipts
                        </Button>
                        <Button
                            onClick={() => {
                                setSelectedReceiptType("Other")
                                loadOtherReceipts()
                            }}
                            style={{
                                backgroundColor: selectedReceiptType === "Other" ? 'white' : 'silver'
                            }}>
                            Other Receipts
                        </Button>
                    </Space>
                    <table class="w3-table-all">
                        <tr>
                            <th>Receipt ID</th>
                            <th>Receipt No.</th>
                            <th>Receipt Date</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Mode</th>
                            <th>Prepare By</th>
                            <th>Status</th>
                            <th>Del. Status</th>
                        </tr>
                        {filterReceiptList.map((item, index) => {
                            return (
                                <tr>
                                    <td>{item.receipt_id}</td>
                                    <td>{item.receipt_number}</td>
                                    <td>{item.receipt_date}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.type}</td>
                                    <td>{item.mode}</td>
                                    <td>{item.prepare_by}</td>
                                    <td>{item.status}</td>
                                    <td>{item.del_status}</td>
                                    {/* <input
                                    style={{ marginRight: 10 }}
                                    value="Generate Notice"
                                    className={"issueNoticeButton"}
                                    type="button"
                                    onClick={() => onButtonClick(item)} /> */}
                                    <Space direction="horizontal">
                                        {/* <input
                                        style={{ marginRight: 10 }}
                                        value="Show Ledger"
                                        className={"issueNoticeButton"}
                                        type="button"
                                        onClick={() => onLedgerClick(item)} /> */}
                                        <input
                                            style={{ marginRight: 10 }}
                                            value="Print Receipt"
                                            className={"issueNoticeButton"}
                                            type="button"
                                            onClick={() => onButtonClick(item)}
                                        />
                                        {item.type === "Suspense" ?
                                        <input
                                            style={{ marginRight: 10 }}
                                            value="Assign to Plot"
                                            className={"issueNoticeButton"}
                                            type="button"
                                            onClick={() => navigateToEditPlotList(item.id, item.amount)}
                                        />: null}
                                    </Space>
                                </tr>
                            )
                        })}
                    </table>
                    {/* End page content */}
                </div>}
            <Modal
                visible={showReceiptTypeModal}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {

                }}
                onCancel={() => {
                    setShowReceiptTypeModal(false);
                }}>
                <div>

                </div>
            </Modal>
        </>
    )
}

export default OtherReceiptList;