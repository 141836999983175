import React, { useState } from "react";
import Sidebar from "../../Components/SideMenu/Sidebar";
import { addPlot } from "../../API";
import { useNavigate } from "react-router-dom";

const AddPlot = () => {

    const navigation = useNavigate();
    const [plotNumber, setPlotNumber] = useState('');
    const [plotNumberError, setPlotNumberError] = useState('');
    const [plotArea, setPlotArea] = useState('');
    const [plotAreaError, setPlotAreaError] = useState('');
    const [plotAddress, setPlotAddress] = useState('');
    const [plotAddressError, setPlotAddressError] = useState('');
    const [plotCity, setPlotCity] = useState('');
    const [plotCityError, setPlotCityError] = useState('');
    const [firm, setFirm] = useState('');
    const [firmError, setFirmError] = useState('');
    const [plotStatus, setPlotStatus] = useState('');
    const [plotStatusError, setPlotStatusError] = useState('');
    const [plotOwnerName, setPlotOwnerName] = useState('');
    const [plotOwnerNameError, setPlotOwnerNameError] = useState('');
    const [plotOwnerMobile, setPlotOwnerMobile] = useState('');
    const [plotOwnerMobileError, setPlotOwnerMobileError] = useState('');
    const [plotOwnerEmail, setPlotOwnerEmail] = useState('');
    const [plotOwnerEmailError, setPlotOwnerEmailError] = useState('');

    function onButtonClick() {
        if (plotNumber === "") {
            setPlotNumberError("Please enter plot Number.");
        } else if (plotArea === "") {
            setPlotAreaError("Please enter proper plot Area.");
        } else if (plotCity === "") {
            setPlotCityError("Please enter proper plot City.");
        } else if (plotAddress === "" || plotAddress.length < 5) {
            setPlotAddressError("Please enter proper plot Address.");
        } else if (firm === "" || firm.length < 3) {
            setFirmError("Please enter proper Firm name.");
        } else if (plotOwnerMobile === "" || plotOwnerMobile.length != 10) {
            setPlotOwnerMobileError("Please enter proper plot owner mobile")
        } else if (plotOwnerEmail === "") {
            setPlotOwnerEmailError("Please enter proper plot owner email address")
        } else {
            addPlot(plotNumber, plotArea, plotAddress, plotCity, firm, plotOwnerName, plotOwnerMobile, plotOwnerEmail)
            .then(res => {
                navigation('/plotlist')
                console.log(res)
            })
            .catch((error) => console.log(error))
        }
    }

    return (
        <>
            <title>W3.CSS Template</title>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Raleway"
            />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        '\nhtml,body,h1,h2,h3,h4,h5 {font-family: "Raleway", sans-serif}\n'
                }}
            />
            {/* Sidebar/menu */}
            <div className="sideMenu">
                <Sidebar showHide={true} />
            </div>
            {/* Overlay effect when opening sidebar on small screens */}
            <div
                className="w3-overlay w3-hide-large w3-animate-opacity"
                onclick="w3_close()"
                style={{ cursor: "pointer" }}
                title="close side menu"
                id="myOverlay"
            />
            <div className="w3-main" style={{ marginLeft: 300, marginTop: 43 }}>
                <div className={"inputContainer"}>
                    <h2>Add Plot</h2>
                    <p>Plot Number</p>
                    <input
                        value={plotNumber}
                        placeholder="Enter Plot Number"
                        onChange={ev => setPlotNumber(ev.target.value)}
                        className={"inputBox"} />
                    <label className="errorLabel">{plotNumberError}</label>
                    <p>Plot Area</p>
                    <input
                        value={plotArea}
                        placeholder="Enter Plot Area"
                        onChange={ev => setPlotArea(ev.target.value)}
                        className={"inputBox"} />
                    <label className="errorLabel">{plotAreaError}</label>
                    <p>Plot Address</p>
                    <input
                        value={plotAddress}
                        placeholder="Enter Plot Address"
                        onChange={ev => setPlotAddress(ev.target.value)}
                        className={"inputBox"} />
                    <label className="errorLabel">{plotAddressError}</label>
                    <p>Plot City</p>
                    <input
                        value={plotCity}
                        placeholder="Enter Plot City"
                        onChange={ev => setPlotCity(ev.target.value)}
                        className={"inputBox"} />
                    <label className="errorLabel">{plotCityError}</label>
                    <p>Plot Firm</p>
                    <input
                        value={firm}
                        placeholder="Enter Plot Firm"
                        onChange={ev => setFirm(ev.target.value)}
                        className={"inputBox"} />
                    <label className="errorLabel">{firmError}</label>
                    {/* <p>Plot Status</p>
                    <input
                        value={plotStatus}
                        placeholder="Enter Plot Status"
                        onChange={ev => setPlotStatus(ev.target.value)}
                        className={"inputBox"} />
                    <label className="errorLabel">{plotStatusError}</label> */}
                    <p>Plot Owner Name</p>
                    <input
                        value={plotOwnerName}
                        placeholder="Enter Plot Owner Name"
                        onChange={ev => setPlotOwnerName(ev.target.value)}
                        className={"inputBox"} />
                    <label className="errorLabel">{plotOwnerNameError}</label>
                    <p>Plot Owner Mobile</p>
                    <input
                        value={plotOwnerMobile}
                        placeholder="Enter Plot Owner Mobile"
                        onChange={ev => setPlotOwnerMobile(ev.target.value)}
                        className={"inputBox"} />
                    <label className="errorLabel">{plotOwnerMobileError}</label>
                    <p>Plot Owner Email</p>
                    <input
                        value={plotOwnerEmail}
                        placeholder="Enter Plot Owner Email"
                        onChange={ev => setPlotOwnerEmail(ev.target.value)}
                        className={"inputBox"} />
                    <label className="errorLabel">{plotOwnerEmailError}</label>
                </div>
                <div className={"inputContainer"} style={{ margin: 40 }}>
                    <input
                        value="Add Plot"
                        className={"inputButton"}
                        type="button"
                        onClick={onButtonClick} />
                </div>
            </div>
        </>
    )
}

export default AddPlot;