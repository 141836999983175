import { BASE_URL } from "../Utils/Constants";

export const loginUser = (username, password) => {
    let formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", password);
    return fetch(BASE_URL + "login.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getPlotList = () => {
    let formdata = new FormData();
    formdata.append("username", "");
    return fetch(BASE_URL + "plotOwnerList.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getPlotDetails = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "getPlotDetails.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getLastReceiptNumber = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "getLastReceiptNumber.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getLastLedgerEntry = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "getLastLedgerEntry.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const searchPlotList = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "searchPlotList.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const addReceiptLedgerEntry = (id, amount, outstandingMF, outstandingTotal, receiptDate) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("amt_received_mf", amount);
    formdata.append("amt_received_dpc", "0");
    formdata.append("amt_received_total", amount);
    formdata.append("amt_outstanding_mf", outstandingMF);
    formdata.append("amt_outstanding_total", outstandingTotal);
    formdata.append("recp_no_recp_date", receiptDate);
    return fetch(BASE_URL + "editReceipt.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const addFIDReceiptLedgerEntry = (id, amount, outstandingMF, outstandingTotal, receiptDate) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("amt_received_mf", amount);
    formdata.append("amt_received_dpc", "0");
    formdata.append("amt_received_total", amount);
    formdata.append("amt_outstanding_mf", outstandingMF);
    formdata.append("amt_outstanding_total", outstandingTotal);
    formdata.append("recp_no_recp_date", receiptDate);
    return fetch(BASE_URL + "editFIDReceipt.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const addPlot = (plot_number, area, address, city, firm, plot_owner, plot_owner_mobile, plot_owner_email) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    formdata.append("area", area);
    formdata.append("address", address);
    formdata.append("city", city);
    formdata.append("firm", firm);
    formdata.append("plot_owner", plot_owner);
    formdata.append("plot_owner_mobile", plot_owner_mobile);
    formdata.append("plot_owner_email", plot_owner_email);
    return fetch(BASE_URL + "addPlotOwner.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const addReceipt = (plot_number, receipt_number, receipt_date, amount, received_from, type, mode, cheque_neft_number, prepare_by, status, bank_charges, surcharges, mfdpc, dues_clear_upto, remarks) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    formdata.append("receipt_number", receipt_number);
    formdata.append("receipt_date", receipt_date);
    formdata.append("received_from", received_from);
    formdata.append("amount", amount);
    formdata.append("type", type);
    formdata.append("mode", mode);
    formdata.append("cheque_neft_number", cheque_neft_number);
    formdata.append("prepare_by", prepare_by);
    formdata.append("status", status);
    formdata.append("bank_charges", bank_charges);
    formdata.append("surcharges", surcharges);
    formdata.append("mfdpc", mfdpc);
    formdata.append("dues_clear_upto", dues_clear_upto);
    formdata.append("remarks", remarks);
    return fetch(BASE_URL + "addReceipt.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const addOtherReceipt = (plot_number, receipt_number, receipt_date, amount, received_from, receipt_head, type, mode, cheque_neft_number, prepare_by, status, bank_charges, surcharges, mfdpc, dues_clear_upto, remarks) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    formdata.append("receipt_number", receipt_number);
    formdata.append("receipt_date", receipt_date);
    formdata.append("received_from", received_from);
    formdata.append("receipt_head", receipt_head);
    formdata.append("amount", amount);
    formdata.append("type", type);
    formdata.append("mode", mode);
    formdata.append("cheque_neft_number", cheque_neft_number);
    formdata.append("prepare_by", prepare_by);
    formdata.append("status", status);
    formdata.append("bank_charges", bank_charges);
    formdata.append("surcharges", surcharges);
    formdata.append("mfdpc", mfdpc);
    formdata.append("dues_clear_upto", dues_clear_upto);
    formdata.append("remarks", remarks);
    return fetch(BASE_URL + "addReceipt.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const editSuspenseReceipt = (plot_number, type, dues_clear_upto, id) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    formdata.append("type", type);
    formdata.append("dues_clear_upto", dues_clear_upto);
    formdata.append("id", id);
    return fetch(BASE_URL + "editSuspenseReceipt.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getLedgers = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "ledgerList.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getLedgersByID = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "ledgerListById.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getFIDLedgers = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "fidLedgerList.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const editLedgerByID = (opening_balance_dpc, dpc_opn_bln_mf_mth, dpc_opn_bln_mf_rate, dpc_opn_bln_mf_amt, current_dpc_rate, current_dpc_mth, current_dpc_amt, total_amt_due_mf, total_amt_due_dpc, total_amt_due_total, amt_outstanding_mf, amt_outstanding_dpc, amt_outstanding_total, id) => {
    let formdata = new FormData();
    formdata.append("opening_balance_dpc", opening_balance_dpc);
    formdata.append("dpc_opn_bln_mf_mth", dpc_opn_bln_mf_mth);
    formdata.append("dpc_opn_bln_mf_rate", dpc_opn_bln_mf_rate);
    formdata.append("dpc_opn_bln_mf_amt", dpc_opn_bln_mf_amt);

    formdata.append("current_dpc_rate", current_dpc_rate);
    formdata.append("current_dpc_mth", current_dpc_mth);
    formdata.append("current_dpc_amt", current_dpc_amt);
    formdata.append("total_amt_due_mf", total_amt_due_mf);

    formdata.append("total_amt_due_dpc", total_amt_due_dpc);
    formdata.append("total_amt_due_total", total_amt_due_total);
    formdata.append("amt_outstanding_mf", amt_outstanding_mf);
    formdata.append("amt_outstanding_dpc", amt_outstanding_dpc);
    formdata.append("amt_outstanding_total", amt_outstanding_total);
    formdata.append("id", id);

    return fetch(BASE_URL + "editLedgerByID.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getQuarterDates = () => {
    let formdata = new FormData();
    formdata.append("plot_number", "1");
    return fetch(BASE_URL + "getQuarterDates.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getReceipts = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "getReceiptsList.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getAllFIDReceipts = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "getFIDReceipts.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getFIDReceipts = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "getFIDReceiptList.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getOtherReceipts = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "getOtherReceipts.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getSuspenseReceipts = (plot_number) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    return fetch(BASE_URL + "getSuspenseReceipts.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const addLedgers = (plot_number, from_date, to_date, opening_balance_mf, opening_balance_dpc, dpc_opn_bln_mf_mth, dpc_opn_bln_mf_rate, dpc_opn_bln_mf_amt, current_demand,
    current_dpc_mth, current_dpc_rate, current_dpc_amt, total_amt_due_mf, total_amt_due_dpc, total_amt_due_total,
    amt_received_mf, amt_received_dpc, amt_received_total, amt_outstanding_mf, amt_outstanding_dpc, amt_outstanding_total, recp_no_recp_date) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("opening_balance_mf", opening_balance_mf);
    formdata.append("opening_balance_dpc", opening_balance_dpc);
    formdata.append("dpc_opn_bln_mf_mth", dpc_opn_bln_mf_mth);
    formdata.append("dpc_opn_bln_mf_rate", dpc_opn_bln_mf_rate);
    formdata.append("dpc_opn_bln_mf_amt", dpc_opn_bln_mf_amt);
    formdata.append("current_demand", current_demand);
    formdata.append("current_dpc_mth", current_dpc_mth);
    formdata.append("current_dpc_rate", current_dpc_rate);
    formdata.append("current_dpc_amt", current_dpc_amt);
    formdata.append("total_amt_due_mf", total_amt_due_mf);
    formdata.append("total_amt_due_dpc", total_amt_due_dpc);
    formdata.append("total_amt_due_total", total_amt_due_total);
    formdata.append("amt_received_mf", amt_received_mf);
    formdata.append("amt_received_dpc", amt_received_dpc);
    formdata.append("amt_received_total", amt_received_total);
    formdata.append("amt_outstanding_mf", amt_outstanding_mf);
    formdata.append("amt_outstanding_dpc", amt_outstanding_dpc);
    formdata.append("amt_outstanding_total", amt_outstanding_total);
    formdata.append("recp_no_recp_date", recp_no_recp_date);
    return fetch(BASE_URL + "addToLedger.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const addFIDLedgers = (plot_number, from_date, to_date, opening_balance_mf, opening_balance_dpc, dpc_opn_bln_mf_mth, dpc_opn_bln_mf_rate, dpc_opn_bln_mf_amt, current_demand,
    current_dpc_mth, current_dpc_rate, current_dpc_amt, total_amt_due_mf, total_amt_due_dpc, total_amt_due_total,
    amt_received_mf, amt_received_dpc, amt_received_total, amt_outstanding_mf, amt_outstanding_dpc, amt_outstanding_total, recp_no_recp_date) => {
    let formdata = new FormData();
    formdata.append("plot_number", plot_number);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("opening_balance_mf", opening_balance_mf);
    formdata.append("opening_balance_dpc", opening_balance_dpc);
    formdata.append("dpc_opn_bln_mf_mth", dpc_opn_bln_mf_mth);
    formdata.append("dpc_opn_bln_mf_rate", dpc_opn_bln_mf_rate);
    formdata.append("dpc_opn_bln_mf_amt", dpc_opn_bln_mf_amt);
    formdata.append("current_demand", current_demand);
    formdata.append("current_dpc_mth", current_dpc_mth);
    formdata.append("current_dpc_rate", current_dpc_rate);
    formdata.append("current_dpc_amt", current_dpc_amt);
    formdata.append("total_amt_due_mf", total_amt_due_mf);
    formdata.append("total_amt_due_dpc", total_amt_due_dpc);
    formdata.append("total_amt_due_total", total_amt_due_total);
    formdata.append("amt_received_mf", amt_received_mf);
    formdata.append("amt_received_dpc", amt_received_dpc);
    formdata.append("amt_received_total", amt_received_total);
    formdata.append("amt_outstanding_mf", amt_outstanding_mf);
    formdata.append("amt_outstanding_dpc", amt_outstanding_dpc);
    formdata.append("amt_outstanding_total", amt_outstanding_total);
    formdata.append("recp_no_recp_date", recp_no_recp_date);
    return fetch(BASE_URL + "addToFIDLedger.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}