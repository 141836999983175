import React, { useEffect, useState } from "react";
import Styles from '../Login/index.css'
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../API";

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigation = useNavigate();
    function onButtonClick() {
        if (username === "") {
            alert("Please enter username.")
        } else if (password === "") {
            alert("Please enter password");
        } else {
            loginUser(username, password)
            .then(res => {
                if (res.Users.length === 0) {
                    alert("You entered wrong username or password");
                } else {
                    navigation('/dashboard')
                }
            })
        }
    }


    return (
        <>
            <title>MMRI SMC</title>
            <link href="css/bootstrap.min.css" rel="stylesheet" />
            <link href="fonts/css/font-awesome.min.css" rel="stylesheet" />
            <link href="css/animate.min.css" rel="stylesheet" />
            {/* Custom styling plus plugins */}
            <link href="css/custom.css" rel="stylesheet" />
            <link href="css/icheck/flat/green.css" rel="stylesheet" />
            {/*[if lt IE 9]>
            
            <![endif]*/}
            {/* HTML5 shim and Respond.js for IE8 support of HTML5 elements and media queries */}
            {/*[if lt IE 9]>
                
                
            <![endif]*/}
            <div className="">
                <a className="hiddenanchor" id="toregister" />
                <a className="hiddenanchor" id="tologin" />
                <div id="wrapper" style={{ marginTop: "1%" }}>
                <div id="login" className="animateform">
                    <section className="login_content">
                    <form id="form1" runat="server">
                        <h1>MMRI SMC </h1>
                        <img src={require('../../Assets/images/Logo.jpg')} height="200px" width="200px" />
                        <h1>Login Form</h1>
                        <div className={'mainContainer'}>
                            <div >
                                <input
                                    value={username}
                                    placeholder="Enter your email here"
                                    onChange={ev => setUsername(ev.target.value)}
                                    className={"inputBox"} />
                                {/* <label className="errorLabel">{emailError}</label> */}
                            </div>
                            <br />
                            <div >
                                <input
                                    value={password}
                                    placeholder="Enter your password here"
                                    onChange={ev => setPassword(ev.target.value)}
                                    className={"inputBox"} 
                                    type="password"/>
                                {/* <label className="errorLabel">{passwordError}</label> */}
                            </div>
                            <br />
                            <div >
                                <input
                                    value="Log In"
                                    className={"inputButton"}
                                    type="button"
                                    onClick={onButtonClick}/>
                            </div>
                        </div>
                        
                        <div className="clearfix" />
                        <div className="separator">
                        <div className="clearfix" />
                        <br />
                        <div>
                            <p>©2017 All Rights Reserved. </p>
                        </div>
                        </div>
                    </form>
                    {/* form */}
                    </section>
                    {/* content */}
                </div>
                </div>
            </div>
        </>
    )
}

export default Login;