import { Routes, Route } from "react-router-dom";
import Login from "../../Pages/Login";
import Dashboard from "../../Pages/Dashboard";
import IssueNotice from "../../Pages/IssueNotice";
import PreviewPage from "../../Pages/PreviewPage";
import Ledger from "../../Pages/Ledger";
import AddLedger from "../../Pages/AddLedger";
import AddPlot from "../../Pages/AddPlotList";
import PlotList from "../../Pages/PlotList";
import AddReceipt from "../../Pages/AddReceipt";
import MFDPCList from "../../Pages/MFDPCList";
import ReceiptList from "../../Pages/ReceiptList";
import ReceiptPreviewPage from "../../Pages/ReceiptPreveiwPage";
import OtherReceiptList from "../../Pages/OtherReceipts";
import EditReceiptPlotList from "../../Pages/EditReceiptPlotList";
import EditReceipt from "../../Pages/EditReceipt";
import FIDPlotList from "../../Pages/FIDPlotList";
import FIDLedger from "../../Pages/FIDLedger";
import AddFIDLedger from "../../Pages/AddFIDLedger";
import ReminderOnePlotList from "../../Pages/ReminderOnePlotList";
import ReminderTwoPlotList from "../../Pages/ReminderTwoPlotList";
import FIDReceiptList from "../../Pages/FIDReceiptList";
import AddPlotLedger from "../../Pages/AddPlotLedger";
import EditPlotLedger from "../../Pages/EditPlotLedger";
import EditPlotList from "../../Pages/EditPlotList";

function AppRoutes () {
    return (
        <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/issuenotice" element={<IssueNotice />}></Route>
            <Route path="/previewpage" element={<PreviewPage />}></Route>
            <Route path="/receiptpreviewpage" element={<ReceiptPreviewPage />}></Route>
            <Route path="/ledger" element={<Ledger />}></Route>
            <Route path="/addledger" element={<AddLedger />}></Route>
            <Route path="/addplot" element={<AddPlot />}></Route>
            <Route path="/plotlist" element={<PlotList />}></Route>
            <Route path="/reminderoneplotlist" element={<ReminderOnePlotList />}></Route>
            <Route path="/remindertwoplotlist" element={<ReminderTwoPlotList />}></Route>
            <Route path="/receiptlist" element={<ReceiptList />}></Route>
            <Route path="/fidreceiptlist" element={<FIDReceiptList />}></Route>
            <Route path="/mfdpclist" element={<MFDPCList />}></Route>
            <Route path="/addreceipt" element={<AddReceipt />}></Route>
            <Route path="/otherreceiptlist" element={<OtherReceiptList />}></Route>
            <Route path="/editreceiptplotlist" element={<EditReceiptPlotList />}></Route>
            <Route path="/editreceipt" element={<EditReceipt />}></Route>
            <Route path="/fidplotlist" element={<FIDPlotList />}></Route>
            <Route path="/fidledger" element={<FIDLedger />}></Route>
            <Route path="/addfidledger" element={<AddFIDLedger />}></Route>
            <Route path="/addplotledger" element={<AddPlotLedger />}></Route>
            <Route path="/editplotlist" element={<EditPlotList />}></Route>
            <Route path="/editplotledger" element={<EditPlotLedger />}></Route>
        </Routes>
    )
}

export default AppRoutes;