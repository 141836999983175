import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  {
    title: 'Home',
    path: '/dashboard',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'Masters',
    path: '/dashboard',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Plot List',
        path: '/plotlist',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Edit Ledger',
        path: '/editplotlist',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Plot Holder',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Name Change',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'License Holder',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'User',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'SMS Send',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Mail Send',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'RRC Report',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'Receipt',
    path: '/dashboard',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Check MF DPC',
        path: '/mfdpclist',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Demoand Notice',
        path: '/plotlist',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Reminder One',
        path: '/reminderoneplotlist',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Reminder Two',
        path: '/remindertwoplotlist',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'RRC',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'FID Final Notice',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Market Fee Register',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'FID Register',
        path: '/fidplotlist',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'FID Demand - New',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'FID Reminder One',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'FID Reminder Two',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
    ]
  },
  {
    title: 'Accounts',
    path: '/dashboard',
    icon: <IoIcons.IoMdPeople />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Name Change',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Plot Holder',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Excel Upload for Cheque Clear',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Receipt Cheque Clear',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Payment Cheque Clear',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Ledger Head',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Subledger Head',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Payment Voucher',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Journal Voucher',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Contra Voucher',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
    ]
  },
  {
    title: 'Accounts Reports',
    path: '/dashboard',
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Receipt List',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Trial Balance',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Income Expenditure',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Balance Sheet',
        path: '/dashboard',
        icon: <IoIcons.IoIosPaper />
      },
    ]
  },
  {
    title: 'Reports',
    path: '/support',
    icon: <IoIcons.IoMdHelpCircle />
  }
];
