import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/SideMenu/Sidebar";
import Styles from '../AddLedger/index.css';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addFIDLedgers, addLedgers, getFIDLedgers, getFIDReceipts, getLedgers, getQuarterDates, getReceipts } from "../../API";
import { useLocation, useNavigate } from "react-router-dom";
import { Space } from "antd";

const AddFIDLedger = () => {

    const { state } = useLocation();
    const { data } = state;

    const navigation = useNavigate();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [openingBalanceMF, setOpeningBalanceMF] = useState(0);
    const [openingBalanceMFError, setopeningBalanceMFError] = useState('')

    const [openingBalanceDPC, setOpeningBalanceDPC] = useState(0);
    const [openingBalanceDPCError, setopeningBalanceDPCError] = useState('')

    const [dpcOnOpeningBlnceMth, setDpcOnOpeningBlnceMth] = useState(0);
    const [dpcOnOpeningBlnceMthError, setDpcOnOpeningBlnceMthError] = useState('')

    const [dpcOnOpeningBlnceRate, setDpcOnOpeningBlnceRate] = useState(0);
    const [dpcOnOpeningBlnceRateError, setDpcOnOpeningBlnceRateError] = useState('')

    const [dpcOnOpeningBlnceAmount, setDpcOnOpeningBlnceAmount] = useState(0);
    const [dpcOnOpeningBlnceAmountError, setDpcOnOpeningBlnceAmountError] = useState('')

    const [currentDemand, setCurrentDemand] = useState(5625);
    const [currentDemandError, setCurrentDemandError] = useState('')

    const [currentDPCMths, setCurrentDPCMths] = useState(0);
    const [currentDPCMthsError, setCurrentDPCMthsError] = useState('')

    const [currentDPCRate, setCurrentDPCRate] = useState(0);
    const [currentDPCRateError, setCurrentDPCRateError] = useState('')

    const [currentDPCAmount, setCurrentDPCAmount] = useState(0);
    const [currentDPCAmountError, setCurrentDPCAmountError] = useState('')

    const [totalAmountDueMF, setTotalAmountDueMF] = useState(0);
    const [totalAmountDueMFError, setTotalAmountDueMFError] = useState('')

    const [totalAmountDueDPC, setTotalAmountDueDPC] = useState(0);
    const [totalAmountDueDPCError, setTotalAmountDueDPCError] = useState('')

    const [totalAmountDueTotal, setTotalAmountDueTotal] = useState(0);
    const [totalAmountDueTotalError, setTotalAmountDueTotalError] = useState('')

    const [amountReceivedMF, setAmountReceivedMF] = useState(0);
    const [amountReceivedMFError, setAmountReceivedMFError] = useState('')

    const [amountReceivedDPC, setAmountReceivedDPC] = useState(0);
    const [amountReceivedDPCError, setAmountReceivedDPCError] = useState('')

    const [amountReceivedTotal, setAmountReceivedTotal] = useState(0);
    const [amountReceivedTotalError, setAmountReceivedTotalError] = useState('')

    const [amountOutstandingMF, setAmountOutstandingMF] = useState(0);
    const [amountOutstandingMFError, setAmountOutstandingMFError] = useState('')

    const [amountOutstandingDPC, setAmountOutstandingDPC] = useState(0);
    const [amountOutstandingDPCError, setAmountOutstandingDPCError] = useState('')

    const [amountOutstandingTotal, setAmountOutstandingTotal] = useState(0);
    const [amountOutstandingTotalError, setAmountOutstandingTotalError] = useState('')

    const [receiptNumber, setReceiptNumber] = useState('')
    const [receiptDate, setReceiptDate] = useState('0-')

    const [ledgersList, setLegdersList] = useState([])
    const [showSubmitBtn, setShowSubmitBtn] = useState(false)
    const [editLedger, setEditLedger] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        getQuarterDates()
            .then(res => {
                setStartDate(res.Dates[0].start_date)
                setEndDate(res.Dates[0].end_date)
                getReceiptsData(res.Dates[0].start_date);
            })
    }, [])

    const getReceiptsData = (startDate) => {
        getFIDReceipts(data.plot_number)
            .then(res => {
                var lastReceiptDate = "";
                if (res.Receipts.length === 0) {
                    setCurrentDPCMths(0)
                } else {
                    lastReceiptDate = res.Receipts[res.Receipts.length - 1].receipt_date;
                    var dateArray = lastReceiptDate.split("/");
                    var day = dateArray[0];
                    var month = dateArray[1];
                    var year = dateArray[2];
                    console.log(dateArray)

                    let newDate = new Date()
                    let todaydate = newDate.getDate();
                    let todaymonth = newDate.getMonth() + 1;
                    let todayyear = newDate.getFullYear();

                    const difference = test(new Date(year, month, day), new Date(todayyear, todaymonth, todaydate))
                    if (difference === NaN) {
                        setCurrentDPCMths(0)
                    } else {
                        setCurrentDPCMths(difference)
                    }
                }
                getPlotLedgers(startDate);
            })
    }

    const getPlotLedgers = (startDate) => {
        getFIDLedgers(data.plot_number)
            .then(res => {
                setLegdersList(res.Ledgers)
                if (res.Ledgers.length === 0) {
                    setOpeningBalanceMF(0)
                    setOpeningBalanceDPC(0)
                    setTotalAmountDueMF(0 + parseInt(currentDemand))
                    setTotalAmountDueTotal(0 + parseInt(currentDemand) + parseInt(totalAmountDueDPC))
                    setAmountOutstandingMF(0 + parseInt(currentDemand) + parseInt(totalAmountDueDPC))
                    setAmountOutstandingTotal(0 + parseInt(currentDemand) + parseInt(totalAmountDueDPC) + parseInt(amountOutstandingDPC))
                } else {
                    if (parseInt(res.Ledgers[res.Ledgers.length - 1].amt_received_mf) === 0) {
                        setOpeningBalanceMF(parseInt(res.Ledgers[res.Ledgers.length - 1].opening_balance_mf) + parseInt(res.Ledgers[res.Ledgers.length - 1].current_demand))
                        setOpeningBalanceDPC(parseInt(res.Ledgers[res.Ledgers.length - 1].current_dpc_amt))
                        setTotalAmountDueMF(parseInt(res.Ledgers[res.Ledgers.length - 1].opening_balance_mf) + parseInt(res.Ledgers[res.Ledgers.length - 1].current_demand) + parseInt(currentDemand))
                        setTotalAmountDueTotal(parseInt(res.Ledgers[res.Ledgers.length - 1].opening_balance_mf) + parseInt(res.Ledgers[res.Ledgers.length - 1].current_demand) + parseInt(currentDemand))
                        setAmountOutstandingMF(parseInt(res.Ledgers[res.Ledgers.length - 1].opening_balance_mf) + parseInt(res.Ledgers[res.Ledgers.length - 1].current_demand) + parseInt(currentDemand))
                        setAmountOutstandingTotal(parseInt(res.Ledgers[res.Ledgers.length - 1].opening_balance_mf) + parseInt(res.Ledgers[res.Ledgers.length - 1].current_demand) + parseInt(currentDemand))
                    } else {
                        setOpeningBalanceMF(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf))
                        setOpeningBalanceDPC(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_dpc))
                        setTotalAmountDueMF(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf) + parseInt(res.Ledgers[res.Ledgers.length - 1].current_demand) + parseInt(currentDemand))
                        setTotalAmountDueTotal(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf) + parseInt(res.Ledgers[res.Ledgers.length - 1].current_demand) + parseInt(currentDemand))
                        setAmountOutstandingMF(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf) + parseInt(res.Ledgers[res.Ledgers.length - 1].current_demand) + parseInt(currentDemand))
                        setAmountOutstandingTotal(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf) + parseInt(res.Ledgers[res.Ledgers.length - 1].current_demand) + parseInt(currentDemand))
                    }
                    if (res.Ledgers[res.Ledgers.length - 1].from_date === startDate) {
                        setEditLedger(true)
                        navigation('/previewpage', { state: { data: data, noticeType: 'FID', type: "FID" } })
                    } else {
                        setEditLedger(false)
                    }
                }
                setLoading(false)
            })
    }

    function monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        console.log(d1)
        console.log(d2)
        console.log(months)
        return months <= 0 || months === NaN ? 0 : months;
    }

    function test(d1, d2) {
        var diff = monthDiff(d1, d2);
        return diff
    }

    function onButtonClick() {
        checkForm();
    }

    function onButtonClickSubmit() {
        addFIDLedgers(data.plot_number, startDate, endDate, openingBalanceMF, openingBalanceDPC, dpcOnOpeningBlnceMth, dpcOnOpeningBlnceRate,
            dpcOnOpeningBlnceAmount, currentDemand, currentDPCMths, currentDPCRate, currentDPCAmount, totalAmountDueMF, totalAmountDueDPC,
            totalAmountDueTotal, amountReceivedMF, amountReceivedDPC, amountReceivedTotal, amountOutstandingMF, amountOutstandingDPC, amountOutstandingTotal, receiptNumber + "" + receiptDate)
            .then(res => {
                //navigation('/ledger', { state: { data: data }})
                alert('Entry added to FID ledger successfully.')
                navigation('/previewpage', { state: { data: data, noticeType: 'FID', type: "FID" } })
            }).catch(error => console.log(error))
    }


    const checkForm = () => {
        if (openingBalanceMF === "" || openingBalanceMF === null) {
            setopeningBalanceMFError("Opening balance MF can not be Empty.")
        } else if (openingBalanceDPC === "" || openingBalanceMF === null) {
            setopeningBalanceDPCError("Opening balance DPC can not be Empty.")
        } else if (dpcOnOpeningBlnceMth === "" || openingBalanceMF === null) {
            setDpcOnOpeningBlnceMthError("DPC opening balance month can not be Empty.")
        } else if (dpcOnOpeningBlnceRate === "" || openingBalanceMF === null) {
            setDpcOnOpeningBlnceRateError("DPC opening balance rate can not be Empty.")
        } else if (dpcOnOpeningBlnceAmount === "" || openingBalanceMF === null) {
            setDpcOnOpeningBlnceAmountError("DPC opening balance amount can not be Empty.")
        } else if (currentDemand === "" || openingBalanceMF === null) {
            setCurrentDemandError("Current Demand can not be Empty.")
        } else if (currentDPCMths === "" || openingBalanceMF === null) {
            setCurrentDPCMthsError("Current DPC months can not be Empty.")
        } else if (currentDPCRate === "" || openingBalanceMF === null) {
            setCurrentDPCRateError("Current DPC rate can not be Empty.")
        } else if (currentDPCAmount === "" || openingBalanceMF === null) {
            setCurrentDPCAmountError("Current DPC amount can not be Empty.")
        } else if (totalAmountDueMF === "" || openingBalanceMF === null) {
            setTotalAmountDueMFError("Total Amount Due MF can not be Empty.")
        } else if (totalAmountDueDPC === "" || openingBalanceMF === null) {
            setTotalAmountDueDPCError("Total Amount Due DPC can not be Empty.")
        } else if (amountReceivedMF === "" || openingBalanceMF === null) {
            setAmountReceivedMFError("Amount Received MF can not be Empty.")
        } else if (amountReceivedDPC === "" || openingBalanceMF === null) {
            setAmountReceivedDPCError("Amount Received DPC can not be Empty.")
        } else if (amountReceivedTotal === "" || openingBalanceMF === null) {
            setAmountReceivedTotalError("Amount Received Total can not be Empty.")
        } else if (amountOutstandingDPC === "" || openingBalanceMF === null) {
            setAmountOutstandingDPCError("Amount Outstanding DPC can not be Empty.")
        } else {
            calculateDPC();
        }
    }

    const calculateDPC = () => {
        // if (parseInt(currentDPCMths) === 0) {

        // } else 
        if (parseInt(currentDPCMths) <= 6) {
            var tempCurrentDPCTotal = parseInt(currentDemand) * 0.19 * parseInt(currentDPCMths) / 12
            setCurrentDPCRate(19)
            setCurrentDPCAmount(parseInt(tempCurrentDPCTotal));
            setTotalAmountDueDPC(parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
            setAmountOutstandingDPC(parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
            setTotalAmountDueTotal(parseInt(openingBalanceMF) + parseInt(currentDemand) + parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
            setAmountOutstandingMF((parseInt(openingBalanceMF) + parseInt(currentDemand) + parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal)) - (parseInt(amountReceivedMF) + parseInt(amountReceivedDPC)))
            setAmountOutstandingTotal((parseInt(openingBalanceMF) + parseInt(currentDemand) + parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal)) - (parseInt(amountReceivedMF) + parseInt(amountReceivedDPC)) + parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
        } else {
            var tempCurrentDPCTotal = parseInt(currentDemand) * 0.24 * parseInt(currentDPCMths) / 12
            setCurrentDPCRate(24)
            setCurrentDPCAmount(parseInt(tempCurrentDPCTotal));
            setCurrentDPCAmount(parseInt(tempCurrentDPCTotal));
            setTotalAmountDueDPC(parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
            setAmountOutstandingDPC(parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
        }
        setShowSubmitBtn(true)
        //addLedgerAPI()
    }

    return (
        <>
            <title>W3.CSS Template</title>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Raleway"
            />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        '\nhtml,body,h1,h2,h3,h4,h5 {font-family: "Raleway", sans-serif}\n'
                }}
            />
            {/* Sidebar/menu */}
            {/* <div className="sideMenu">
                <Sidebar showHide={false} />
            </div> */}
            {/* Overlay effect when opening sidebar on small screens */}
            <div
                className="w3-overlay w3-hide-large w3-animate-opacity"
                onclick="w3_close()"
                style={{ cursor: "pointer" }}
                title="close side menu"
                id="myOverlay"
            />
            {loading ? 
            <div className="w3-main">
                <p>Loading ...</p>
            </div>:
            <div className="w3-main" style={{ marginLeft: 50, marginTop: 43 }}>
                <h2>Confirm Ledger Details</h2>
                <div className={"inputContainer"}>
                    {/* <div className={"w3-row"}>
                        <div>
                            <p>Start Date</p>
                            <ReactDatePicker locale="es" selected={startDate} onChange={(date) => setStartDate(date.toISOString().substring(0, 10))} />
                        </div>
                        <div>
                            <p>End Date</p>
                            <ReactDatePicker locale="es" selected={endDate} onChange={(date) => setEndDate(date.toISOString().substring(0, 10))} />
                        </div>
                    </div> */}
                    <Space direction="horizontal">
                        <div>
                            <p>Opening Balance MF</p>
                            <input
                                readOnly
                                value={openingBalanceMF}
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                        <div>
                            <p>Opening Balance DPC </p>
                            <input
                                value={openingBalanceDPC}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                        <div>
                            <p>DPC on Opening Balance Mths</p>
                            <input
                                value={dpcOnOpeningBlnceMth}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                    </Space>
                    <Space direction="horizontal">
                        <div>
                            <p>DPC on Opening Balance Rate</p>
                            <input
                                readOnly
                                value={dpcOnOpeningBlnceRate}
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />

                        </div>
                        <div>
                            <p>DPC on Opening Balance Amount</p>
                            <input
                                value={dpcOnOpeningBlnceAmount}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                        <div>
                            <p>Current Demand</p>
                            <input
                                value={currentDemand}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                    </Space>
                    <Space direction="horizontal">
                        <div>
                            <p>Current DPC Months</p>
                            <input
                                readOnly
                                value={currentDPCMths === NaN ? 0 : currentDPCMths}
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />

                        </div>
                        <div>
                            <p>Current DPC Rate</p>
                            <input
                                value={currentDPCRate}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                        <div>
                            <p>Current DPC Amount</p>
                            <input
                                value={currentDPCAmount}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                    </Space>
                    <Space direction="horizontal">
                        <div>
                            <p>Total Amount Due MF</p>
                            <input
                                readOnly
                                value={totalAmountDueMF}
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />

                        </div>
                        <div>
                            <p>Total Amount Due DPC</p>
                            <input
                                value={totalAmountDueDPC}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                        <div>
                            <p>Total Amount Due Total</p>
                            <input
                                value={totalAmountDueTotal}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                    </Space>
                    <Space direction="horizontal">
                        <div>
                            <p>Amount Received MF</p>
                            <input
                                readOnly
                                value={amountReceivedMF}
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />

                        </div>
                        <div>
                            <p>Amount Received DPC</p>
                            <input
                                value={amountReceivedDPC}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                        <div>
                            <p>Amount Received Total</p>
                            <input
                                value={amountReceivedTotal}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                    </Space>
                    <Space direction="horizontal">
                        <div>
                            <p>Amount Outstanding MF</p>
                            <input
                                readOnly
                                value={amountOutstandingMF}
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />

                        </div>
                        <div>
                            <p>Amount Outstanding DPC</p>
                            <input
                                value={amountOutstandingDPC}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                        <div>
                            <p>Amount Outstanding Total</p>
                            <input
                                value={amountOutstandingTotal}
                                readOnly
                                style={{ backgroundColor: 'lightgrey' }}
                                className={"inputBox"} />
                        </div>
                    </Space>
                    <Space
                        direction="horizontal">
                        <div className={"inputContainer"} style={{ margin: 40 }}>
                            <input
                                value="Calculate"
                                className={"inputButton"}
                                type="button"
                                onClick={onButtonClick} />
                        </div>
                        {showSubmitBtn ?
                            <div className={"inputContainer"} style={{ marginLeft: 100 }}>
                                <input
                                    value="Confirm Details"
                                    className={"inputButton"}
                                    type="button"
                                    onClick={onButtonClickSubmit} />
                            </div> : null}
                    </Space>
                </div>
            </div>}
        </>
    )
}

export default AddFIDLedger;