import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../Components/SideMenu/Sidebar";
import Styles from '../IssueNotice/index.css';
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "../../Components/ComponentToPrint";
import { useNavigate, useLocation } from "react-router-dom";
import { getPlotList, searchPlotList } from "../../API";
import { Space, Modal, Select } from "antd";

const EditReceiptPlotList = () => {
    const navigation = useNavigate();
    const { state } = useLocation();
    const { id, amount } = state;
    const [plotList, setPlotList] = useState([]);
    const [filterPlotList, setFilterPlotList] = useState([]);
    const [searchPlot, setSearchPlot] = useState('')
    const [searchPlotMobile, setSearchPlotMobile] = useState('')
    const [searchPlotNumber, setSearchPlotNumber] = useState('')
    const [showReceiptTypeModal, setShowReceiptTypeModal] = useState(false);
    const [receiptType, setReceiptType] = useState('')
    const [selectedData, setSelectedData] = useState()
    
    const onReceiptClick = (data) => {
        navigation('/receiptlist', { state: { data: data } })
    }
    function onAddLedgerClick(data, type, id) {
        // if (type === "FID") {
        //     setShowReceiptTypeModal(false)
        // } else {
        //     navigation('/addreceipt', { state: { data: data, typeSelected: type } })
        // }
        navigation('/editreceipt', { state: { data: data, typeSelected: type, id: id, amountEdit: amount } })
    }

    function onOtherReceipts () {
        navigation('/otherreceiptlist');
    }

    useEffect(() => {
        getPlotList()
            .then(res => {
                setPlotList(res.Plots)
                setFilterPlotList(res.Plots)
            })
    }, [])

    function AddPlot() {
        navigation('/addplot')
    }

    function refreshSearch() {
        getPlotList()
            .then(res => {
                setPlotList(res.Plots)
                setFilterPlotList(res.Plots)
            })
    }

    function searchByPlotNumber() {
        searchPlotList(searchPlotNumber)
            .then(res => {
                setPlotList(res.Plots)
                setFilterPlotList(res.Plots)
            })
    }

    return (
        <>
            <title>W3.CSS Template</title>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Raleway"
            />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        '\nhtml,body,h1,h2,h3,h4,h5 {font-family: "Raleway", sans-serif}\n'
                }}
            />
            {/* Sidebar/menu */}
            <div className="sideMenu">
                <Sidebar showHide={true} />
            </div>
            {/* Overlay effect when opening sidebar on small screens */}
            <div
                className="w3-overlay w3-hide-large w3-animate-opacity"
                onclick="w3_close()"
                style={{ cursor: "pointer" }}
                title="close side menu"
                id="myOverlay"
            />
            {/* !PAGE CONTENT! */}
            <div className="w3-main" style={{ marginLeft: 300, marginTop: 43, marginRight: 20 }}>
                {/* Header */}
                <header className="w3-container" style={{ paddingTop: 22 }}>

                </header>
                {/* <input
                    style={{ marginBottom: 30 }}
                    value="Add Plot"
                    type="button"
                    onClick={AddPlot} /> */}
                <div id="container">
                    <div id="inner">
                        <Space direction="horizontal">
                            <div class="child">
                                <p>Search By Plot Number</p>
                                <input
                                    style={{ marginBottom: 30 }}
                                    value={searchPlotNumber}
                                    placeholder="Enter Plot Number"
                                    onChange={ev => {
                                        setSearchPlotNumber(ev.target.value)
                                        //searchPlotNumberText(ev.target.value)
                                    }}
                                    className={"inputBox"} />
                            </div>
                            <input
                                style={{ marginRight: 10, fontSize: 18, marginTop: 25 }}
                                value="Search Plot"
                                className={"issueNoticeButton"}
                                type="button"
                                onClick={searchByPlotNumber}
                            />
                            <input
                                style={{ marginRight: 10, fontSize: 18, marginTop: 25 }}
                                value="Show All Plots"
                                className={"issueNoticeButton"}
                                type="button"
                                onClick={refreshSearch}
                            />
                        </Space>
                    </div>
                </div>
                <table class="w3-table-all">
                    <tr>
                        <th>Plot No.</th>
                        <th>Firm</th>
                        <th>Mobile</th>
                        <th>Address</th>
                        <th>Plot Area</th>
                        <th></th>
                        <th></th>
                    </tr>
                    {filterPlotList.map((item, index) => {
                        return (
                            <tr>
                                <td>{item.plot_number}</td>
                                <td>{item.plot_owner}</td>
                                <td>{item.plot_owner_mobile}</td>
                                <td>{item.address}</td>
                                <td>{item.area}</td>
                                <td>{item.status}</td>
                                {/* <input
                                    style={{ marginRight: 10 }}
                                    value="Generate Notice"
                                    className={"issueNoticeButton"}
                                    type="button"
                                    onClick={() => onButtonClick(item)} /> */}
                                <Space direction="horizontal">
                                    <input
                                        style={{ marginRight: 10 }}
                                        value="Select Plot"
                                        className={"issueNoticeButton"}
                                        type="button"
                                        onClick={() => {
                                            // setShowReceiptTypeModal(true)
                                            // setSelectedData(item)
                                            onAddLedgerClick(item, "MFDPC", id)
                                        }} />
                                </Space>
                            </tr>
                        )
                    })}
                </table>
                {/* End page content */}
                <Modal
                    visible={showReceiptTypeModal}
                    okText="Next"
                    closable={false}
                    cancelText="Close"
                    onOk={() => {
                        onAddLedgerClick(selectedData, "MFDPC")
                    }}
                    onCancel={() => {
                        setShowReceiptTypeModal(false);
                    }}>
                    <div>
                        <p>Select Type</p>
                        <Select
                            className={"inputBox"}
                            placeholder="Select Mode"
                            onChange={(value) => {
                                setReceiptType(value)
                            }}
                            options={[
                                {
                                    value: 'MFDPC',
                                    label: 'MFDPC'
                                },
                                {
                                    value: 'Suspense',
                                    label: 'Suspense'
                                },
                                {
                                    value: 'FID',
                                    label: 'FID'
                                },
                                {
                                    value: 'Other',
                                    label: 'Other'
                                }
                            ]} />
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default EditReceiptPlotList;