import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/SideMenu/Sidebar";
import Styles from '../AddLedger/index.css';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addLedgers, editLedgerByID, getLedgers, getLedgersByID, getQuarterDates, getReceipts } from "../../API";
import { useLocation, useNavigate } from "react-router-dom";
import { Space } from "antd";

const EditPlotLedger = () => {

    const { state } = useLocation();
    const { data, type } = state;

    const navigation = useNavigate();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [ledgerEndDate, setLedgerEndDate] = useState('');

    const [openingBalanceMF, setOpeningBalanceMF] = useState(0);
    const [openingBalanceMFError, setopeningBalanceMFError] = useState('')

    const [openingBalanceDPCDisplay, setOpeningBalanceDPCDisplay] = useState(0);

    const [openingBalanceDPC, setOpeningBalanceDPC] = useState(0);
    const [openingBalanceDPCError, setopeningBalanceDPCError] = useState('')

    const [dpcOnOpeningBlnceMth, setDpcOnOpeningBlnceMth] = useState(0);
    const [dpcOnOpeningBlnceMthError, setDpcOnOpeningBlnceMthError] = useState('')

    const [dpcOnOpeningBlnceRate, setDpcOnOpeningBlnceRate] = useState(0);
    const [dpcOnOpeningBlnceRateError, setDpcOnOpeningBlnceRateError] = useState('')

    const [dpcOnOpeningBlnceAmount, setDpcOnOpeningBlnceAmount] = useState(0);
    const [dpcOnOpeningBlnceAmountError, setDpcOnOpeningBlnceAmountError] = useState('')

    const [currentDemand, setCurrentDemand] = useState(0);
    const [currentDemandError, setCurrentDemandError] = useState('')

    const [currentDPCMths, setCurrentDPCMths] = useState(0);
    const [currentDPCMthsError, setCurrentDPCMthsError] = useState('')

    const [currentDPCRate, setCurrentDPCRate] = useState(0);
    const [currentDPCRateError, setCurrentDPCRateError] = useState('')

    const [currentDPCAmount, setCurrentDPCAmount] = useState(0);
    const [currentDPCAmountError, setCurrentDPCAmountError] = useState('')

    const [totalAmountDueMF, setTotalAmountDueMF] = useState(0);
    const [totalAmountDueMFError, setTotalAmountDueMFError] = useState('')

    const [totalAmountDueDPC, setTotalAmountDueDPC] = useState(0);
    const [totalAmountDueDPCError, setTotalAmountDueDPCError] = useState('')

    const [totalAmountDueTotal, setTotalAmountDueTotal] = useState(0);
    const [totalAmountDueTotalError, setTotalAmountDueTotalError] = useState('')

    const [amountReceivedMF, setAmountReceivedMF] = useState(0);
    const [amountReceivedMFError, setAmountReceivedMFError] = useState('')

    const [amountReceivedDPC, setAmountReceivedDPC] = useState(0);
    const [amountReceivedDPCError, setAmountReceivedDPCError] = useState('')

    const [amountReceivedTotal, setAmountReceivedTotal] = useState(0);
    const [amountReceivedTotalError, setAmountReceivedTotalError] = useState('')

    const [amountOutstandingMF, setAmountOutstandingMF] = useState(0);
    const [amountOutstandingMFError, setAmountOutstandingMFError] = useState('')

    const [amountOutstandingDPC, setAmountOutstandingDPC] = useState(0);
    const [amountOutstandingDPCError, setAmountOutstandingDPCError] = useState('')

    const [amountOutstandingTotal, setAmountOutstandingTotal] = useState(0);
    const [amountOutstandingTotalError, setAmountOutstandingTotalError] = useState('')
    const [previousAmountDPC, setPreviousAmountDPC] = useState(0);

    const [receiptNumber, setReceiptNumber] = useState('')
    const [receiptDate, setReceiptDate] = useState('0-')

    const [ledgersList, setLegdersList] = useState([])
    const [showSubmitBtn, setShowSubmitBtn] = useState(false)
    const [editLedger, setEditLedger] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalDPCAmount, setTotalDPCAmount] = useState(0);

    const [showLedger, setShowLedger] = useState(false);
    const [selectedID, setSelectedID] = useState('');
    const [openingBalanceValue, setOpeningBalanceValue] = useState('');
    const [lastCountNumber, setLastCountNumber] = useState();
    const [selectedPosition, setSelectedPosition] = useState(0);

    useEffect(() => {
        if (parseInt(data.area) === 125) {
            setCurrentDemand(2813)
            loadQuarterDates();
        } else if (parseInt(data.area) === 250) {
            setCurrentDemand(5625)
            loadQuarterDates();
        } else if (parseInt(data.area) === 450) {
            setCurrentDemand(10125)
            loadQuarterDates();
        } else if (parseInt(data.area) === 900) {
            setCurrentDemand(20250)
            loadQuarterDates();
        } else {
            var tempDemand = parseInt(data.area) * 45;
            tempDemand = tempDemand / 2;
            setCurrentDemand(tempDemand);
            loadQuarterDates();
        }
        //loadQuarterDates();
    }, [])

    const loadQuarterDates = () => {
        setLoading(true)
        getQuarterDates()
            .then(res => {
                setStartDate(res.Dates[0].start_date)
                setEndDate(res.Dates[0].end_date)
                setLedgerEndDate(res.Dates[0].ledger_end_date)
                //getReceiptsData(res.Dates[0].start_date);
            })
    }

    const getLedgerLastUpdatedTillPayment = () => {
        getLedgers(data.plot_number)
            .then(res => {
                var lastRecordWithReceipt = 0;
                var selectedID = 0
                for (let i = 0; i < res.Ledgers.length; i++) {
                    if (res.Ledgers[i].recp_no_recp_date === "-" || res.Ledgers[i].recp_no_recp_date === "0-") {

                    } else {
                        //alert(parseInt(res.Ledgers.length) - parseInt(i + 1))
                        //alert(parseInt(res.Ledgers[i + 1].current_dpc_mth) + 2)
                        setSelectedPosition(i + 1);
                        setLastCountNumber(parseInt(res.Ledgers.length) - parseInt(i + 1))
                        setCurrentDPCMths(parseInt(res.Ledgers[i + 1].current_dpc_mth) + 2)
                        //editRecords(parseInt(res.Ledgers.length) - parseInt(i + 1))
                        setSelectedID(res.Ledgers[i + 1].id);
                        lastRecordWithReceipt = parseInt(res.Ledgers.length) - parseInt(i + 1);
                        selectedID = res.Ledgers[i + 1].id;
                    }
                }
                getPlotLedgers(lastRecordWithReceipt)

            })
    }

    // const getLedgerUpdateNextPayment = (count) => {
    //     getLedgers(data.plot_number)
    //         .then(res => {
    //             var lastRecordWithReceipt = 0;
    //             var remainingEntries = 0
    //             for (let i = 0; i < res.Ledgers.length; i++) {
    //                 if (res.Ledgers[i].recp_no_recp_date === "-" || res.Ledgers[i].recp_no_recp_date === "0-") {

    //                 } else {
    //                     //alert(parseInt(res.Ledgers.length) - parseInt(i + 1))
    //                     //alert(parseInt(res.Ledgers[i + 1].current_dpc_mth) + 2)
    //                     setLastCountNumber(parseInt(res.Ledgers.length) - parseInt(i + 1))
    //                     setCurrentDPCMths(parseInt(res.Ledgers[i + 1].current_dpc_mth) + 2)
    //                     //editRecords(parseInt(res.Ledgers.length) - parseInt(i + 1))
    //                     setSelectedID(res.Ledgers[i + 1].id);
    //                 }
    //             }

    //         })
    // }

    // const editRecords = (numberOfRecordsToEdit) => {

    // }

    const decreaseLastCountNumber = () => {
        setSelectedPosition(selectedPosition => selectedPosition - 1)
        getLedgers(data.plot_number)
            .then(res => {
                var lastRecordWithReceipt = 0;
                var remainingEntries = 0
                // for (let i = 0; i < res.Ledgers.length; i++) {
                //     if (res.Ledgers[i].recp_no_recp_date === "-" || res.Ledgers[i].recp_no_recp_date === "0-") {

                //     } else {
                //         //alert(parseInt(res.Ledgers.length) - parseInt(i + 1))
                //         //alert(parseInt(res.Ledgers[i + 1].current_dpc_mth) + 2)
                //         setLastCountNumber(parseInt(res.Ledgers.length) - parseInt(selectedPosition))
                //         setCurrentDPCMths(parseInt(res.Ledgers[selectedPosition].current_dpc_mth) + 2)
                //         //editRecords(parseInt(res.Ledgers.length) - parseInt(i + 1))
                //         setSelectedID(res.Ledgers[selectedPosition].id);
                //     }
                // }
                setSelectedPosition(selectedPosition + 1);
                setLastCountNumber(parseInt(res.Ledgers.length) - parseInt(selectedPosition + 1))
                setCurrentDPCMths(parseInt(res.Ledgers[selectedPosition + 1].current_dpc_mth) + 2)
                //editRecords(parseInt(res.Ledgers.length) - parseInt(i + 1))
                setSelectedID(res.Ledgers[selectedPosition + 1].id);

                getPlotLedgers(parseInt(res.Ledgers.length) - parseInt(selectedPosition + 1));
            })
    }

    const getReceiptsData = (startDate) => {
        getReceipts(data.plot_number)
            .then(res => {
                var lastReceiptDate = "";
                if (res.Receipts.length === 0) {
                    //setCurrentDPCMths(0)
                } else {
                    lastReceiptDate = res.Receipts[res.Receipts.length - 1].receipt_date;
                    var dateArray = lastReceiptDate.split("/");
                    var day = dateArray[0];
                    var month = dateArray[1];
                    var year = dateArray[2];
                    console.log(dateArray)

                    let newDate = new Date()
                    let todaydate = newDate.getDate();
                    let todaymonth = newDate.getMonth() + 1;
                    let todayyear = newDate.getFullYear();

                    const difference = test(new Date(year, month, day), new Date(todayyear, todaymonth, todaydate))
                    console.log(difference);
                    if (difference === NaN) {
                        setCurrentDPCMths(0)
                    } else {
                        setCurrentDPCMths(difference)
                    }
                }
                setLoading(false)
            })
    }

    const getPlotLedgers = (lastCountNumber) => {
        getLedgers(data.plot_number)
            .then(res => {
                setLegdersList(res.Ledgers)
                // if (res.Ledgers.length === 0) {
                //     setOpeningBalanceMF(0)
                //     setOpeningBalanceDPC(0)
                //     setTotalAmountDueMF(0 + parseInt(currentDemand))
                //     setTotalAmountDueTotal(0 + parseInt(currentDemand) + parseInt(totalAmountDueDPC))
                //     setAmountOutstandingMF(0 + parseInt(currentDemand) + parseInt(totalAmountDueDPC))
                //     setAmountOutstandingTotal(0 + parseInt(currentDemand) + parseInt(totalAmountDueDPC) + parseInt(amountOutstandingDPC))
                // } else {
                //alert(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_received_mf)
                if (parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].opening_balance_mf) === 0) {
                    //alert("here 0");
                    setOpeningBalanceMF(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].opening_balance_mf))
                    setOpeningBalanceDPC(0)
                    setOpeningBalanceDPCDisplay(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_dpc))
                    setPreviousAmountDPC(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].total_amt_due_dpc))
                    setTotalAmountDueMF(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf) + parseInt(currentDemand))
                    //setTotalAmountDueTotal(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf) + parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].current_demand) + parseInt(currentDemand))
                    setAmountOutstandingMF(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf) + parseInt(currentDemand))
                } else {
                    //alert("here 1")
                    setOpeningBalanceMF(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].opening_balance_mf))
                    setOpeningBalanceDPC(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber - 1].total_amt_due_dpc))
                    setOpeningBalanceDPCDisplay(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_dpc))
                    setPreviousAmountDPC(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].total_amt_due_dpc))
                    setTotalAmountDueMF(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf) + parseInt(currentDemand))
                    //setTotalAmountDueTotal(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf) + parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].current_demand) + parseInt(currentDemand))
                    setAmountOutstandingMF(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf) + parseInt(currentDemand))
                }
                // if (res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf.indexOf('-') > -1) {
                //     setOpeningBalanceMF(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf)
                //     setOpeningBalanceValue(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf)
                // } else {
                //     setOpeningBalanceMF(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf))
                //     setOpeningBalanceValue("0")
                // }

                //setAmountOutstandingTotal(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf) + parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].current_demand) + parseInt(currentDemand))

                // }
                // setLoading(false)
            })
    }

    function monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        console.log(d1)
        console.log(d2)
        console.log(months)
        return months <= 0 || months === NaN ? 0 : months;
    }

    function test(d1, d2) {
        var diff = monthDiff(d1, d2);
        return diff
    }

    function onButtonClickSubmit() {
        if (lastCountNumber <= 0) {
            addLedgers(data.plot_number, startDate, ledgerEndDate, openingBalanceMF, openingBalanceDPCDisplay, dpcOnOpeningBlnceMth, dpcOnOpeningBlnceRate,
                dpcOnOpeningBlnceAmount, currentDemand, 5, 19, totalDPCAmount, totalAmountDueMF, totalAmountDueDPC,
                totalAmountDueTotal, amountReceivedMF, amountReceivedDPC, amountReceivedTotal, amountOutstandingMF, amountOutstandingDPC, amountOutstandingTotal, receiptNumber + "" + receiptDate)
                .then(res => {
                    //navigation('/ledger', { state: { data: data }})
                    // alert('Entry added to ledger successfully.')
                    // navigation('/ledger', { state: { data: data }, replace: true })
                    alert("Ledger added Successfully.")
                }).catch(error => console.log(error))
        } else {
            editLedgerByID(openingBalanceDPC, dpcOnOpeningBlnceMth, dpcOnOpeningBlnceRate, dpcOnOpeningBlnceAmount, currentDPCRate, currentDPCMths, currentDPCAmount, totalAmountDueMF, totalAmountDueDPC, totalAmountDueTotal, amountOutstandingMF, amountOutstandingDPC, amountOutstandingTotal, selectedID)
                .then(res => {
                    alert("Ledger Edited Successfully.")
                })
                .catch(error => console.log(error))
        }

    }

    const addLedgerAPI = () => {
        addLedgers(data.plot_number, startDate, ledgerEndDate, openingBalanceMF, openingBalanceDPCDisplay, dpcOnOpeningBlnceMth, dpcOnOpeningBlnceRate,
            dpcOnOpeningBlnceAmount, currentDemand, currentDPCMths, currentDPCRate, totalDPCAmount, totalAmountDueMF, totalAmountDueDPC,
            totalAmountDueTotal, amountReceivedMF, amountReceivedDPC, amountReceivedTotal, amountOutstandingMF, amountOutstandingDPC, amountOutstandingTotal, receiptNumber + "" + receiptDate)
            .then(res => {
                //navigation('/ledger', { state: { data: data }})
                // alert('Entry added to ledger successfully.')
                // navigation('/ledger', { state: { data: data }, replace: true })
                allLedgers();
                setShowLedger(true)
            }).catch(error => console.log(error))
    }

    const allLedgers = () => {
        getLedgers(data.plot_number)
            .then(res => {
                setLegdersList(res.Ledgers)
            })
    }

    const calculateDPC = () => {
        // if (parseInt(currentDPCMths) === 0) {

        // } else 
        alert("DPC Calculated")
        if (parseInt(currentDPCMths) <= 6) {
            var tempCurrentDPCTotal = parseInt(currentDemand) * 0.19 * parseInt(currentDPCMths) / 12;
            setCurrentDPCRate(19)
            setTotalDPCAmount(parseInt(tempCurrentDPCTotal));
            setCurrentDPCAmount(parseInt(tempCurrentDPCTotal));
            //tempCurrentDPCTotal = tempCurrentDPCTotal + previousAmountDPC
            //setTotalAmountDueMF(parseInt(openingBalanceMF) + parseInt(currentDemand))
            setTotalAmountDueDPC(parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
            setTotalAmountDueTotal(parseInt(openingBalanceDPC) + parseInt(openingBalanceMF) + parseInt(currentDemand) + parseInt(tempCurrentDPCTotal))
            //setAmountOutstandingMF(parseInt(openingBalanceMF) + parseInt(currentDemand))
            setAmountOutstandingDPC(parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
            setAmountOutstandingTotal(parseInt(openingBalanceDPC) + parseInt(openingBalanceMF) + parseInt(currentDemand) + parseInt(tempCurrentDPCTotal))

        } else {
            var tempCurrentDPCTotal = parseInt(currentDemand) * 0.24 * parseInt(currentDPCMths) / 12;
            setCurrentDPCRate(24)
            setOpeningBalanceMF(parseInt(openingBalanceMF) + parseInt(currentDemand))
            setTotalDPCAmount(parseInt(tempCurrentDPCTotal));
            setCurrentDPCAmount(parseInt(tempCurrentDPCTotal));
            //tempCurrentDPCTotal = tempCurrentDPCTotal + previousAmountDPC
            //setTotalAmountDueMF(parseInt(openingBalanceMF) + parseInt(currentDemand))
            setTotalAmountDueDPC(parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
            setTotalAmountDueTotal(parseInt(openingBalanceDPC) + parseInt(openingBalanceMF) + parseInt(currentDemand) + parseInt(tempCurrentDPCTotal))
            //setAmountOutstandingMF(parseInt(openingBalanceMF) + parseInt(currentDemand))
            setAmountOutstandingDPC(parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
            setAmountOutstandingTotal(parseInt(openingBalanceDPC) + parseInt(openingBalanceMF) + parseInt(currentDemand) + parseInt(tempCurrentDPCTotal))
        }
        setShowSubmitBtn(true)
        //addLedgerAPI()
        //CallAddLedgerAPI()
    }

    const getOpeningBalanceDPC = () => {
        getLedgers(data.plot_number)
            .then(res => {
                if (parseInt(res.Ledgers[res.Ledgers.length - 1].opening_balance_mf) === 0) {
                    //alert("here 0");
                    setOpeningBalanceMF(parseInt(res.Ledgers[res.Ledgers.length - 1].opening_balance_mf))
                    setOpeningBalanceDPC(0)
                    setOpeningBalanceDPCDisplay(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_dpc))
                    setPreviousAmountDPC(parseInt(res.Ledgers[res.Ledgers.length - 1].total_amt_due_dpc))
                    setTotalAmountDueMF(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf) + parseInt(currentDemand))
                    //setTotalAmountDueTotal(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf) + parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].current_demand) + parseInt(currentDemand))
                    setAmountOutstandingMF(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf) + parseInt(currentDemand))
                } else {
                    //alert("here 1")
                    setOpeningBalanceMF(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf))
                    setOpeningBalanceDPC(parseInt(res.Ledgers[res.Ledgers.length - 1].total_amt_due_dpc))
                    setOpeningBalanceDPCDisplay(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_dpc))
                    setPreviousAmountDPC(parseInt(res.Ledgers[res.Ledgers.length - 1].total_amt_due_dpc))
                    setTotalAmountDueMF(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf) + parseInt(currentDemand))
                    //setTotalAmountDueTotal(parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].amt_outstanding_mf) + parseInt(res.Ledgers[res.Ledgers.length - lastCountNumber].current_demand) + parseInt(currentDemand))
                    setAmountOutstandingMF(parseInt(res.Ledgers[res.Ledgers.length - 1].amt_outstanding_mf) + parseInt(currentDemand))
                }
                calculateAddDPC(parseInt(res.Ledgers[res.Ledgers.length - 1].total_amt_due_dpc))
            })
    }

    const calculateAddDPC = (openingBalanceDPC) => {
        // if (parseInt(currentDPCMths) === 0) {

        // } else 
        alert("DPC Calculated")
        var tempCurrentDPCTotal = parseInt(currentDemand) * 0.19 * parseInt(5) / 12;
        setCurrentDPCMths(5)
        setCurrentDPCRate(19)
        setTotalDPCAmount(parseInt(tempCurrentDPCTotal));
        setCurrentDPCAmount(parseInt(tempCurrentDPCTotal));
        //tempCurrentDPCTotal = tempCurrentDPCTotal + previousAmountDPC
        //setTotalAmountDueMF(parseInt(openingBalanceMF) + parseInt(currentDemand))
        setTotalAmountDueDPC(parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
        setTotalAmountDueTotal(parseInt(openingBalanceDPC) + parseInt(openingBalanceMF) + parseInt(currentDemand) + parseInt(tempCurrentDPCTotal))
        //setAmountOutstandingMF(parseInt(openingBalanceMF) + parseInt(currentDemand))
        setAmountOutstandingDPC(parseInt(openingBalanceDPC) + parseInt(tempCurrentDPCTotal))
        setAmountOutstandingTotal(parseInt(openingBalanceDPC) + parseInt(openingBalanceMF) + parseInt(currentDemand) + parseInt(tempCurrentDPCTotal))
        setShowSubmitBtn(true)
        //addLedgerAPI()
        //CallAddLedgerAPI()
    }

    return (
        <>
            <div>
                <p>Plot Number: {data.plot_number}</p>
                <Space direction="horizontal">
                    <div>
                        <p>Last Entries DPC Month</p>
                        <input
                            value={currentDPCMths}
                            readOnly
                            style={{ backgroundColor: 'lightgrey' }}
                            //onChange={ev => setCurrentDPCMths(ev.target.value)}
                            className={"inputBox"} />
                    </div>
                    <div>
                        <p>Last Entry ID</p>
                        <input
                            value={selectedID}
                            readOnly
                            style={{ backgroundColor: 'lightgrey' }}
                            //onChange={ev => setCurrentDPCMths(ev.target.value)}
                            className={"inputBox"} />
                    </div>
                </Space>
                <Space direction="horizontal">
                    <div>
                        <p>Last Entries To Edit</p>
                        <input
                            value={lastCountNumber}
                            readOnly
                            style={{ backgroundColor: 'lightgrey' }}
                            onChange={ev => setLastCountNumber(ev.target.value)}
                            className={"inputBox"} />
                    </div>
                </Space>
                <Space direction="horizontal" style={{ marginTop: 50 }}>
                    <div className={"inputContainer"} style={{ marginLeft: 100 }}>
                        <input
                            value="Load First Entries"
                            className={"inputButton"}
                            type="button"
                            onClick={getLedgerLastUpdatedTillPayment} />
                    </div>
                    <div className={"inputContainer"} style={{ marginLeft: 100 }}>
                        <input
                            value="Decrease Last Count"
                            className={"inputButton"}
                            type="button"
                            onClick={decreaseLastCountNumber} />
                    </div>
                    {lastCountNumber === 0 ?
                        <div className={"inputContainer"} style={{ marginLeft: 100 }}>
                            <input
                                value="Calculate Add"
                                className={"inputButton"}
                                type="button"
                                onClick={getOpeningBalanceDPC} />
                        </div> :
                        <div className={"inputContainer"} style={{ marginLeft: 100 }}>
                            <input
                                value="Calculate"
                                className={"inputButton"}
                                type="button"
                                onClick={calculateDPC} />
                        </div>}
                    <div className={"inputContainer"} style={{ marginLeft: 100 }}>
                        <input
                            value="Submit"
                            className={"inputButton"}
                            type="button"
                            onClick={onButtonClickSubmit} />
                    </div>
                </Space>
            </div>
        </>
    )
}

export default EditPlotLedger;